/* eslint-disable react/prop-types */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const HomeIcon = props => (
    <SvgIcon {...props} viewBox="0 2 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
);

export const DashboardIcon = props => (
    <SvgIcon {...props} viewBox="0 2 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M21,11H13V3A8,8 0 0,1 21,11M19,13C19,15.78 17.58,18.23 15.43,19.67L11.58,13H19M11,21C8.22,21 5.77,19.58 4.33,17.43L10.82,13.68L14.56,20.17C13.5,20.7 12.28,21 11,21M3,13A8,8 0 0,1 11,5V12.42L3.83,16.56C3.3,15.5 3,14.28 3,13Z" />
    </SvgIcon>
);

export const ReportIcon = props => (
    <SvgIcon {...props} viewBox="-6 0 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M9.157 0H0v18h14.087V4.93L9.157 0zm2.19 9.783c-.039 0-.077-.006-.115-.012L8.945 12.63a.782.782 0 1 1-1.51.283c0-.086.017-.166.042-.243l-2.232-2.512a.79.79 0 0 1-.358-.013l-1.395 1.394c.018.064.03.13.03.2a.782.782 0 1 1-.583-.754l1.395-1.394a.767.767 0 0 1-.03-.2.782.782 0 1 1 1.566 0 .772.772 0 0 1-.043.244l2.232 2.511a.811.811 0 0 1 .158-.016c.04 0 .078.006.116.012l2.287-2.859a.782.782 0 1 1 .728.5zM9 5.087V.939l4.148 4.148H9z" />
    </SvgIcon>
);

export const FleetIcon = props => (
    <SvgIcon {...props} viewBox="-4 0 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M0 0v1.8h1.8V0H0zm4.5 0v1.8H18V0H4.5zM0 5.4v1.8h1.8V5.4H0zm4.5 0v1.8H18V5.4H4.5zM0 10.8v1.8h1.8v-1.8H0zm4.5 0v1.8H18v-1.8H4.5zM0 16.2V18h1.8v-1.8H0zm4.5 0V18H18v-1.8H4.5z" />
    </SvgIcon>
);

export const AlertIcon = props => (
    <SvgIcon {...props} viewBox="-4 -1 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M18 14.973L9 0 0 14.973h18zm-8.182-1.636H8.182v-1.636h1.636v1.636zm0-3.273H8.182V5.155h1.636v4.91z" />
    </SvgIcon>
);

export const AdminIcon = props => (
    <SvgIcon {...props} viewBox="-4 -1 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M15.425 9.9c0-.27.091-.63.091-.9s0-.63-.091-.9l1.891-1.35c.18-.091.27-.359.092-.63l-1.8-3.15c-.092-.27-.362-.361-.542-.27l-2.158.991a5.695 5.695 0 0 0-1.621-.9L11.017.45c0-.27-.18-.45-.45-.45h-3.6c-.271 0-.45.18-.45.45L6.245 2.7c-.63.27-1.171.541-1.621.9l-2.159-.9c-.18-.091-.45 0-.541.18l-1.8 3.15c-.18.27-.18.54.091.629L2.108 8.1c0 .27-.092.63-.092.9s0 .63.092.9L.216 11.25c-.18.091-.27.359-.091.63l1.8 3.15c.091.27.362.361.541.27l2.159-.991c.45.362 1.083.72 1.62.9l.271 2.341c0 .27.18.45.45.45h3.6c.27 0 .45-.18.45-.45l.27-2.341c.63-.268 1.172-.538 1.622-.9l2.158.991c.18.091.45 0 .542-.18l1.8-3.15c.088-.179.088-.45-.092-.629L15.425 9.9zm-6.659 2.7c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" />
    </SvgIcon>
);

export const DriverIcon = props => (
    <SvgIcon {...props} viewBox="-5 0 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M7.364 0A2.455 2.455 0 0 0 5.05 1.636H1.636C.745 1.636 0 2.381 0 3.273v13.09C0 17.256.745 18 1.636 18h11.455c.892 0 1.636-.745 1.636-1.636V3.273c0-.892-.744-1.637-1.636-1.637H9.678A2.455 2.455 0 0 0 7.364 0zm0 1.636a.82.82 0 0 1 .818.819.82.82 0 0 1-.818.818.82.82 0 0 1-.819-.818.82.82 0 0 1 .819-.819zM1.636 3.273h1.637v2.454h8.182V3.273h1.636v13.09H1.636V3.274zm7.556 3.413l-.87.87 1.202 1.201L8.322 9.96l.87.87 1.201-1.203 1.202 1.202.87-.87-1.202-1.2 1.201-1.203-.869-.869-1.202 1.202-1.201-1.202zm-5.92 1.496v1.636h4.092V8.182H3.273zM9.19 11.02l-.867.87 1.202 1.2-1.202 1.203.867.869 1.204-1.202 1.202 1.202.87-.87-1.205-1.201 1.204-1.202-.869-.87-1.202 1.203L9.19 11.02zm-5.916 1.253v1.636h4.09v-1.636h-4.09z" />
    </SvgIcon>
);

export const DashCamIcon = props => (
    <SvgIcon {...props} width="18" height="12" viewBox="0 0 18 12">
        <g fill="none" fillRule="evenodd">
            <g fill="#4a4a4a" fillRule="nonzero">
                <path data-itemid={props['data-itemid']} d="M937.636 157c-.902 0-1.636.746-1.636 1.664v8.32c0 .917.734 1.663 1.636 1.663h10.637c.902 0 1.636-.746 1.636-1.664v-2.429l4.091 3.328v-10.117l-4.09 3.328v-2.43c0-.917-.735-1.663-1.637-1.663h-10.637z" transform="translate(-936 -157)" />
            </g>
        </g>
    </SvgIcon>
);

export const MaintenanceIcon = props => (
    <SvgIcon {...props} viewBox="-4 -2 24 24">
        <path data-itemid={props['data-itemid']} fill="#4A4A4A" d="M1.385 0L0 1.385l1.385 2.77 2.077.692 2.424 2.424L7.27 5.886 4.847 3.462l-.692-2.077L1.385 0zm12.51.007a4.256 4.256 0 0 0-3.188 1.245c-1.133 1.133-1.234 2.496-.67 3.778l-8.044 8.042a2.076 2.076 0 1 0 2.938 2.938l8.043-8.043c1.281.563 2.644.461 3.777-.672a4.267 4.267 0 0 0 1.047-4.32l-2.566 2.567-2.216-.554-.555-2.217L15.028.206a4.304 4.304 0 0 0-1.133-.2zm-1.07 10.128l-2.606 2.606 3.336 3.338a1.844 1.844 0 1 0 2.608-2.608l-3.338-3.336zm-9.363 3.714a.693.693 0 1 1 0 1.385.693.693 0 0 1 0-1.385z" />
    </SvgIcon>
);

export const ComplianceIcon = props => (
    <SvgIcon {...props}>
        <path data-itemid={props['data-itemid']} d="M20,2 L12.618,2 L12.171,1.106 C11.831,0.424 11.146,0 10.382,0 L2,0 C0.897,0 0,0.897 0,2 L0,16 C0,17.103 0.897,18 2,18 L4,18 L4,16 L6,16 L6,18 L16,18 L16,16 L18,16 L18,18 L20,18 C21.103,18 22,17.103 22,16 L22,4 C22,2.897 21.103,2 20,2 Z M7,5 C8.133,5 9,5.867 9,7 C9,8.133 8.133,9 7,9 C5.867,9 5,8.133 5,7 C5,5.867 5.867,5 7,5 Z M11,13 L3,13 C3,12.843 3,12.376 3,12.217 C3,10.909 4.815,10 7,10 C9.185,10 11,10.909 11,12.217 C11,12.376 11,12.843 11,13 Z M19,13 L13,13 L13,11 L19,11 L19,13 Z M19,9 L13,9 L13,7 L19,7 L19,9 Z" id="Shape" />
    </SvgIcon>
);

export const MapIcon = props => (
    <SvgIcon {...props} viewBox="-4 -2 24 24">
        <path fill="#4A4A4A" fillRule="nonzero" d="M6.3.251L0 3.232V17.8c0 .27.18.45.45.45.091 0 .169.007.169-.084L6.3 15.55l5.4 2.7 6.3-2.981V.7c0-.162-.06-.288-.169-.366a.473.473 0 0 0-.197-.084h-.028c-.017-.004-.035.004-.056 0h-.169l-5.681 1.8L6.3.25zm.084 1.969l4.697 1.546.619.17V16.28L7.116 13.92 6.3 13.58V2.248l.084-.028z" />
    </SvgIcon>
);

export const ChevronLeftIcon = () => (
    <SvgIcon>
        <path
            fill="#fff"
            d="M4.508 8.82h14.32v2.36H4.508l7.153 7.152L9.994 20 0 10.006.006 10 0 9.994 9.994 0l1.667 1.668L4.508 8.82z"
        />
    </SvgIcon>
);

export const LogoIcon = props => (
    <SvgIcon {...props} viewBox="0 0 418 78">
        <title>FleetLocate BySpireon</title>
        <desc>Created with Sketch.</desc>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <polyline fill="#FFFFFF" points="72.43 10.796 72.43 29.832 98.695 29.832 98.695 33.015 72.43 33.015 72.43 54.04 68.981 54.04 68.981 7.613 101.679 7.613 101.679 10.796 72.43 10.796" />
                <polygon fill="#FFFFFF" points="108.911 54.04 112.161 54.04 112.161 5.622 108.911 5.622" />
                <path d="M147.381,35.536 C146.917,28.704 143.003,22.403 135.243,22.403 C128.544,22.403 123.371,28.04 122.775,35.536 L147.381,35.536 Z M136.039,51.785 C141.278,51.785 144.861,49.463 147.646,46.48 L149.901,48.469 C146.452,52.183 142.406,54.836 135.907,54.836 C127.019,54.836 119.325,47.739 119.325,37.194 L119.325,37.061 C119.325,27.244 126.222,19.419 135.376,19.419 C144.861,19.419 150.896,27.113 150.896,37.127 L150.896,37.26 C150.896,37.723 150.896,37.923 150.83,38.453 L122.775,38.453 C123.371,46.877 129.407,51.785 136.039,51.785 L136.039,51.785 Z" fill="#FFFFFF" />
                <path d="M182.802,35.536 C182.338,28.704 178.425,22.403 170.665,22.403 C163.965,22.403 158.792,28.04 158.195,35.536 L182.802,35.536 Z M171.461,51.785 C176.7,51.785 180.282,49.463 183.068,46.48 L185.323,48.469 C181.874,52.183 177.828,54.836 171.328,54.836 C162.44,54.836 154.746,47.739 154.746,37.194 L154.746,37.061 C154.746,27.244 161.644,19.419 170.797,19.419 C180.282,19.419 186.318,27.113 186.318,37.127 L186.318,37.26 C186.318,37.723 186.318,37.923 186.251,38.453 L158.195,38.453 C158.792,46.877 164.827,51.785 171.461,51.785 L171.461,51.785 Z" fill="#FFFFFF" />
                <path d="M203.764,54.637 C198.525,54.637 194.28,51.785 194.28,45.352 L194.28,23.199 L189.371,23.199 L189.371,20.215 L194.28,20.215 L194.28,9.403 L197.529,9.403 L197.529,20.215 L209.402,20.215 L209.402,23.199 L197.529,23.199 L197.529,45.02 C197.529,49.862 200.381,51.586 204.294,51.586 C205.953,51.586 207.345,51.255 209.269,50.392 L209.269,53.444 C207.544,54.24 205.886,54.637 203.764,54.637" fill="#FFFFFF" />
                <polyline fill="#FFFFFF" points="216.434 54.04 216.434 7.613 219.883 7.613 219.883 50.856 247.143 50.856 247.143 54.04 216.434 54.04" />
                <path d="M281.7,37.127 C281.7,28.969 275.598,22.47 267.772,22.47 C259.746,22.47 253.976,29.036 253.976,36.995 L253.976,37.127 C253.976,45.286 260.078,51.785 267.904,51.785 C275.93,51.785 281.7,45.219 281.7,37.26 L281.7,37.127 Z M267.772,54.836 C257.757,54.836 250.461,46.744 250.461,37.26 L250.461,37.127 C250.461,27.642 257.823,19.419 267.904,19.419 C277.92,19.419 285.216,27.511 285.216,36.995 L285.216,37.127 C285.216,46.612 277.854,54.836 267.772,54.836 L267.772,54.836 Z" fill="#FFFFFF" />
                <path d="M306.84,54.836 C297.091,54.836 289.596,46.744 289.596,37.26 L289.596,37.127 C289.596,27.642 297.156,19.419 306.84,19.419 C313.207,19.419 317.188,22.337 320.37,25.653 L318.049,28.04 C315.131,25.056 311.815,22.47 306.774,22.47 C299.08,22.47 293.111,28.903 293.111,36.995 L293.111,37.127 C293.111,45.286 299.279,51.785 307.039,51.785 C311.815,51.785 315.528,49.331 318.381,46.148 L320.636,48.137 C317.188,52.051 313.075,54.836 306.84,54.836" fill="#FFFFFF" />
                <path d="M349.757,37.525 C346.904,36.796 343.123,36 338.281,36 C331.118,36 327.139,39.183 327.139,43.892 L327.139,44.025 C327.139,48.934 331.781,51.785 336.955,51.785 C343.854,51.785 349.757,47.54 349.757,41.372 L349.757,37.525 Z M349.69,54.04 L349.69,48.403 C347.368,51.653 343.191,54.836 336.756,54.836 C330.456,54.836 323.691,51.255 323.691,44.224 L323.691,44.091 C323.691,37.061 329.726,33.082 338.481,33.082 C343.191,33.082 346.44,33.679 349.69,34.54 L349.69,33.082 C349.69,26.25 345.512,22.735 338.548,22.735 C334.435,22.735 331.053,23.863 327.936,25.454 L326.741,22.602 C330.456,20.877 334.104,19.683 338.746,19.683 C347.436,19.683 352.874,24.195 352.874,32.949 L352.874,54.04 L349.69,54.04 L349.69,54.04 Z" fill="#FFFFFF" />
                <path d="M372.167,54.637 C366.928,54.637 362.683,51.785 362.683,45.352 L362.683,23.199 L357.776,23.199 L357.776,20.215 L362.683,20.215 L362.683,9.403 L365.933,9.403 L365.933,20.215 L377.806,20.215 L377.806,23.199 L365.933,23.199 L365.933,45.02 C365.933,49.862 368.785,51.586 372.698,51.586 C374.357,51.586 375.749,51.255 377.673,50.392 L377.673,53.444 C375.948,54.24 374.289,54.637 372.167,54.637" fill="#FFFFFF" />
                <path d="M408.838,35.536 C408.374,28.704 404.461,22.403 396.7,22.403 C390.002,22.403 384.828,28.04 384.232,35.536 L408.838,35.536 Z M397.497,51.785 C402.736,51.785 406.318,49.463 409.104,46.48 L411.359,48.469 C407.91,52.183 403.864,54.836 397.364,54.836 C388.477,54.836 380.782,47.739 380.782,37.194 L380.782,37.061 C380.782,27.244 387.681,19.419 396.833,19.419 C406.318,19.419 412.354,27.113 412.354,37.127 L412.354,37.26 C412.354,37.723 412.354,37.923 412.287,38.453 L384.232,38.453 C384.828,46.877 390.863,51.785 397.497,51.785 L397.497,51.785 Z" fill="#FFFFFF" />
                <path d="M413.777,22.306 L414.328,22.306 C414.778,22.306 415.195,22.284 415.195,21.776 C415.195,21.327 414.815,21.273 414.471,21.273 L413.777,21.273 L413.777,22.306 Z M413.327,20.872 L414.508,20.872 C415.279,20.872 415.641,21.155 415.641,21.797 C415.641,22.374 415.269,22.62 414.793,22.673 L415.719,24.092 L415.236,24.092 L414.35,22.709 L413.777,22.709 L413.777,24.092 L413.327,24.092 L413.327,20.872 Z M414.367,24.84 C415.676,24.84 416.722,23.808 416.722,22.45 C416.722,21.109 415.676,20.086 414.367,20.086 C413.059,20.086 412.013,21.109 412.013,22.477 C412.013,23.808 413.059,24.84 414.367,24.84 Z M414.367,19.683 C415.892,19.683 417.17,20.879 417.17,22.45 C417.17,24.047 415.892,25.242 414.367,25.242 C412.838,25.242 411.565,24.047 411.565,22.477 C411.565,20.879 412.838,19.683 414.367,19.683 L414.367,19.683 Z" fill="#FFFFFF" />
                <path d="M271.746,74.381 C271.746,73.284 270.99,72.311 269.531,72.311 L265.84,72.311 L265.84,76.452 L269.531,76.452 C270.918,76.452 271.746,75.66 271.746,74.381 Z M271.512,69.052 C271.512,67.972 270.792,67.108 269.442,67.108 L265.84,67.108 L265.84,70.979 L269.442,70.979 C270.792,70.979 271.512,70.15 271.512,69.052 Z M264.346,77.785 L264.346,65.775 L269.694,65.775 C271.782,65.775 273.061,67.036 273.061,68.836 C273.061,70.348 272.052,71.357 270.954,71.591 C272.233,71.788 273.295,73.103 273.295,74.543 C273.295,76.47 272.017,77.785 269.819,77.785 L264.346,77.785 L264.346,77.785 Z" fill="#FFFFFF" />
                <g transform="translate(279.000000, 65.000000)">
                    <mask id="logomask-2" fill="white">
                        <polygon points="0.106 0.577 36.036 0.577 36.036 12.94 0.106 12.94" />
                    </mask>
                    <g />
                    <polyline fill="#FFFFFF" mask="url(#logomask-2)" points="4.841 12.785 4.841 7.707 0.106 0.775 1.852 0.775 5.579 6.357 9.306 0.775 11.053 0.775 6.335 7.707 6.335 12.785 4.841 12.785" />
                    <path d="M26.998,11.092 L27.898,9.94 C28.672,10.84 29.969,11.668 31.661,11.668 C33.804,11.668 34.488,10.516 34.488,9.579 C34.488,6.483 27.376,8.211 27.376,3.926 C27.376,1.946 29.141,0.577 31.517,0.577 C33.336,0.577 34.758,1.207 35.767,2.269 L34.866,3.368 C33.966,2.36 32.705,1.927 31.391,1.927 C29.969,1.927 28.924,2.702 28.924,3.836 C28.924,6.536 36.036,4.989 36.036,9.472 C36.036,11.201 34.848,13 31.608,13 C29.536,13 27.988,12.208 26.998,11.092" fill="#FFFFFF" mask="url(#logomask-2)" />
                </g>
                <path d="M329.526,69.394 C329.526,68.043 328.554,67.108 327.131,67.108 L323.98,67.108 L323.98,71.681 L327.131,71.681 C328.554,71.681 329.526,70.744 329.526,69.394 Z M322.486,77.785 L322.486,65.775 L327.311,65.775 C329.724,65.775 331.074,67.431 331.074,69.394 C331.074,71.357 329.706,73.013 327.311,73.013 L323.98,73.013 L323.98,77.785 L322.486,77.785 L322.486,77.785 Z" fill="#FFFFFF" />
                <polygon fill="#FFFFFF" points="338.271 77.785 339.765 77.785 339.765 65.775 338.271 65.775" />
                <path d="M354.852,69.394 C354.852,68.026 353.861,67.108 352.439,67.108 L349.287,67.108 L349.287,71.699 L352.439,71.699 C353.861,71.699 354.852,70.744 354.852,69.394 Z M354.743,77.785 L351.7,73.013 L349.287,73.013 L349.287,77.785 L347.793,77.785 L347.793,65.775 L352.618,65.775 C354.816,65.775 356.4,67.179 356.4,69.394 C356.4,71.555 354.905,72.744 353.267,72.887 L356.508,77.785 L354.743,77.785 L354.743,77.785 Z" fill="#FFFFFF" />
                <g transform="translate(363.000000, 65.000000)">
                    <mask id="logomask-4" fill="white">
                        <polygon points="0.975 0.577 27.888 0.577 27.888 12.94 0.975 12.94" />
                    </mask>
                    <g />
                    <polyline fill="#FFFFFF" mask="url(#logomask-4)" points="0.975 12.785 0.975 0.775 8.843 0.775 8.843 2.108 2.469 2.108 2.469 5.979 8.717 5.979 8.717 7.311 2.469 7.311 2.469 11.452 8.843 11.452 8.843 12.785 0.975 12.785" />
                    <path d="M26.34,6.788 C26.34,3.98 24.593,1.91 21.91,1.91 C19.21,1.91 17.499,3.98 17.499,6.788 C17.499,9.579 19.21,11.668 21.91,11.668 C24.593,11.668 26.34,9.579 26.34,6.788 Z M15.95,6.788 C15.95,3.242 18.363,0.577 21.91,0.577 C25.457,0.577 27.888,3.242 27.888,6.788 C27.888,10.335 25.457,13 21.91,13 C18.363,13 15.95,10.335 15.95,6.788 L15.95,6.788 Z" fill="#FFFFFF" mask="url(#logomask-4)" />
                </g>
                <polyline fill="#FFFFFF" points="406.926 77.785 399.921 68.205 399.921 77.785 398.427 77.785 398.427 65.775 399.957 65.775 406.871 75.156 406.871 65.775 408.366 65.775 408.366 77.785 406.926 77.785" />
                <g>
                    <mask id="logomask-6" fill="white">
                        <polygon points="0.06 0.322 59.6870423 0.322 59.6870423 58.484 0.06 58.484" />
                    </mask>
                    <g />
                    <path d="M58.259,39.4 C58.336,39.153 58.41,38.909 58.478,38.663 C58.562,38.381 58.648,38.102 58.719,37.821 C58.81,37.485 58.885,37.146 58.959,36.81 C59.043,36.447 59.114,36.083 59.182,35.717 C59.248,35.357 59.318,34.999 59.37,34.64 C59.404,34.397 59.431,34.149 59.462,33.903 C59.503,33.58 59.537,33.255 59.568,32.932 C59.589,32.698 59.607,32.46 59.62,32.222 C59.646,31.859 59.66,31.496 59.673,31.129 C59.679,30.943 59.682,30.76 59.686,30.575 C59.695,29.424 59.646,28.277 59.528,27.14 C59.178,23.867 58.29,20.686 56.918,17.716 C56.849,17.569 56.784,17.421 56.71,17.276 C56.679,17.296 56.652,17.315 56.623,17.334 C47.923,29.08 41.904,43.058 39.594,58.484 C47.954,55.582 54.624,49.084 57.754,40.838 C57.818,40.671 57.881,40.504 57.941,40.341 C58.053,40.029 58.155,39.714 58.259,39.4" fill="#FFFFFF" mask="url(#logomask-6)" />
                    <path d="M54.033,12.779 C50.989,8.54 46.797,5.043 41.666,2.811 C38.654,1.505 35.551,0.732 32.449,0.448 C32.299,0.434 32.152,0.426 31.998,0.415 C31.714,0.393 31.429,0.371 31.141,0.356 C30.751,0.341 30.356,0.332 29.961,0.327 C29.897,0.327 29.831,0.322 29.765,0.322 C28.75,0.327 27.744,0.376 26.755,0.474 C26.722,0.48 26.688,0.486 26.655,0.489 C26.203,0.534 25.756,0.59 25.312,0.658 C25.241,0.669 25.168,0.682 25.098,0.694 C24.683,0.758 24.272,0.83 23.864,0.912 C23.795,0.929 23.721,0.942 23.65,0.955 C23.221,1.048 22.799,1.145 22.378,1.251 C22.343,1.259 22.305,1.267 22.274,1.275 C14.648,3.249 8.204,8.155 4.215,14.719 C20.118,9.458 37.407,8.653 54.033,12.779" fill="#FFFFFF" mask="url(#logomask-6)" />
                    <path d="M35.947,19.971 L37.802,21.039 C36.887,21.424 35.98,21.828 35.078,22.244 L32.899,21.129 C33.906,20.727 34.921,20.34 35.947,19.971 Z M29.416,25.08 L27.084,23.703 C28.105,23.205 29.138,22.733 30.18,22.276 L32.37,23.539 C31.378,24.038 30.391,24.544 29.416,25.08 Z M22.837,29.055 L20.111,27.454 C21.418,26.673 22.749,25.924 24.091,25.213 L26.448,26.78 C25.224,27.51 24.023,28.271 22.837,29.055 Z M16.782,33.446 L13.712,31.64 C14.921,30.772 16.155,29.936 17.405,29.129 L20.124,30.938 C18.992,31.75 17.875,32.58 16.782,33.446 Z M10.153,39.242 L6.69,37.204 C8.125,35.947 9.598,34.739 11.104,33.575 L14.163,35.608 C12.796,36.777 11.46,37.988 10.153,39.242 Z M41.24,18.277 L42.728,19.132 C41.969,19.405 41.207,19.667 40.455,19.954 L38.693,19.053 C39.537,18.782 40.384,18.522 41.24,18.277 Z M46.236,16.979 L47.357,17.624 C46.699,17.82 46.04,18.03 45.386,18.241 L43.969,17.516 C44.724,17.32 45.478,17.154 46.236,16.979 Z M50.756,16.061 L51.756,16.44 C51.219,16.571 50.687,16.722 50.153,16.864 L49.171,16.358 C49.699,16.252 50.227,16.153 50.756,16.061 Z M55.092,15.39 C55.139,15.484 55.259,15.577 55.301,15.67 C54.808,15.765 54.376,15.874 53.882,15.978 L53.229,15.67 C53.942,15.563 54.374,15.478 55.092,15.39 Z M56.32,16.432 C56.243,16.279 56.171,16.126 56.087,15.978 C56.081,15.97 56.079,15.962 56.074,15.953 C55.795,15.443 55.498,14.945 55.191,14.451 C34.925,12.325 14.733,19.23 0.019,33.037 C0.295,36.015 1.011,38.865 2.094,41.524 C2.824,40.799 3.562,40.084 4.306,39.384 L7.729,41.652 C6.611,42.805 5.307,44.269 4.145,45.621 C7.137,50.598 11.53,54.628 16.785,57.166 C24.957,38.551 39.276,24.52 56.32,16.432 L56.32,16.432 Z" fill="#FFFFFF" mask="url(#logomask-6)" />
                    <path d="M54.598,13.579 C54.532,13.481 54.462,13.385 54.396,13.286 C36.586,8.828 17.846,11.199 1.849,19.56 C0.876,22.108 0.242,24.82 0,27.651 C15.317,15.867 35.058,10.566 54.598,13.579" fill="#FFFFFF" mask="url(#logomask-6)" />
                </g>
                <path d="M54.448,18.856 C41.423,28.217 30.677,41.105 23.814,56.887 C23.465,57.694 23.133,58.499 22.806,59.312 C25.049,59.847 27.383,60.136 29.789,60.136 C30.865,60.136 31.924,60.079 32.969,59.967 C35.25,44.259 42.844,29.706 54.448,18.856" fill="#FFFFFF" />
            </g>
        </g>
    </SvgIcon>
);

export const IconWrapper = (props) => {
    switch (props['data-itemid']) {
    case 'Logo': {
        return <LogoIcon {...props} />;
    }
    case 'Home': {
        return <HomeIcon {...props} />;
    }
    case 'Dashboard': {
        return <DashboardIcon {...props} />;
    }
    case 'Map': {
        return <MapIcon {...props} />;
    }
    case 'Fleet': {
        return <FleetIcon {...props} />;
    }
    case 'Reports': {
        return <ReportIcon {...props} />;
    }
    case 'Alerts': {
        return <AlertIcon {...props} />;
    }
    case 'Admin': {
        return <AdminIcon {...props} />;
    }
    case 'Driver Behavior': {
        return <DriverIcon {...props} />;
    }
    case 'lmCamCoach':
    case 'CamCoach': {
        return <DashCamIcon {...props} />;
    }
    case 'Maintenance': {
        return <MaintenanceIcon {...props} />;
    }
    case 'Compliance': {
        return <ComplianceIcon {...props} />;
    }
    default: {
        return <HomeIcon {...props} />;
    }
    }
};
