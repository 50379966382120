/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../../hoc/index';
import * as actions from './actions.planList';
import MiniDrawer from '../../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../../components/SharedComponents/ListComponent/ListComponent';
import AddServicePlans from '../../../containers/AddServicePlans/AddServicePlans';
import {
    getDistanceString,
    getDistanceToDisplay,
    getEngineHoursToDisplay,
} from '../../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../../helper-classes/listUtil';
import MaintenanceFilterForm from '../../../components/Maintenance/MaintenanceFilterForm';
import MaintenanceFilterHelper from '../../../components/Maintenance/MaintenanceFilterHelper';
import AppLoader from '../../../components/AppLoader';
import { SERVICE_PLAN_LIST_PREF_KEY } from './constants.planList';
import { RECORDS_PER_PAGE } from '../../../constants/Config';
import { getSplittedHeader } from '../../../helper-classes/utility-react-functions';
import analytics from '../../../analytics';

export type Props = {
    classes: any,
    isUpdating: boolean,
    fetchPlanList: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
};

export type State = {
    tableData: {
        listHeaders: any,
        listData: Array<{}>,
        totalRecords: number,
    },
    page: number,
    order: string,
    orderBy: string,
    editMode: boolean,
    isAddEditShown: boolean,
    selectedPlan: Array<{}>,
    rowsPerPage: number,
    selectedFilters: Array<{}>,
    showListing: boolean,
};

const styles = () => ({
    hide: {
        display: 'none !important',
    },
    show: {
        display: 'block !important',
    },
});

class PlanList extends Component<Props, State> {
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            tableData: {
                ischeckBox: false,
                isEditable: true,
                editTitle: 'Edit Service Plan',
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
            },
            editMode: true,
            isAddEditShown: false,
            selectedPlan: [],
            page: 0,
            order: 'desc',
            orderBy: 'id',
            rowsPerPage: RECORDS_PER_PAGE,
            selectedFilters: [],
            showListing: false,
        };
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, SERVICE_PLAN_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.plans && nextprops.plans.length) {
            const { plans, totalPlans } = nextprops;
            const formattedPlanList = plans.map((item => ({
                ...item,
                distanceInterval: getDistanceToDisplay(item.distanceInterval),
                firstServiceOdometer: getDistanceToDisplay(item.firstServiceOdometer),
                engineHoursInterval: getEngineHoursToDisplay(item.engineHoursInterval),
            })));
            const { tableData } = this.state;
            tableData.listData = formattedPlanList;
            tableData.totalRecords = totalPlans;
            this.setState({
                tableData,
            });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({
                tableData,
            });
        }
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { name: 'Service Plan Name', ...getColumnPreferences('name', preferences), disableSort: true },
            { description: 'Description', ...getColumnPreferences('description', preferences), disableSort: true },
            {
                ...getSplittedHeader(`Distance (${getDistanceString()})`, '', 'distanceInterval'),
                ...getColumnPreferences('distanceInterval', preferences),
            },
            { timePeriodInterval: 'Time Period', displayUnit: 'mo', ...getColumnPreferences('timePeriodInterval', preferences) },
            { engineHoursInterval: 'Engine Hours', displayUnit: 'hrs', ...getColumnPreferences('engineHoursInterval', preferences) },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    getData = () => {
        this.props.fetchPlanList(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.state.selectedFilters,
        );
        this.setState({ showListing: true });
    }

    addClick = () => this.showModal(false);

    showModal = (editMode:boolean) => this.setState({ isAddEditShown: true, editMode });

    clickRow = (n:any) => {
        this.setState({
            selectedPlan: n,
        });
        this.showModal(true);
    };

    closeAddEdit = () => {
        this.setState({
            isAddEditShown: false,
            selectedPlan: [],
        });
        const {
            page, rowsPerPage, order, orderBy, selectedFilters,
        } = this.state;
        this.props.fetchPlanList(page, rowsPerPage, order, orderBy, selectedFilters);
    };

    sortTableColumn = (order, orderBy, page) => {
        this.setState({ order, orderBy, rowsPerPage: page.rowsPerPage }, () => {
            saveUserPreference(this, SERVICE_PLAN_LIST_PREF_KEY);
        });
        this.props.fetchPlanList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.state.selectedFilters,
        );
        const params = {
            feature: 'Maintenance',
            sortColumn: orderBy,
        };
        analytics.track('SERVICE_PLAN_SORT', params);
    };

    onFiltersSelected = (selectedFilters) => {
        const { rowsPerPage, order, orderBy } = this.state;
        this.setState({ selectedFilters });
        this.props.fetchPlanList(0, rowsPerPage, order, orderBy, selectedFilters);
        let assets = true;
        let plans = true;
        selectedFilters.forEach((x) => {
            if (x.property === 'assetId') {
                assets = false;
            }
            if (x.property === 'servicePlanId') {
                plans = false;
            }
        });
        const params = {
            feature: 'Maintenance',
            allAssets: assets,
            allPlans: plans,
        };
        analytics.track('SERVICE_PLAN_FILTER', params);
    };

    filterComponent = () => {
        const filterOptions = [];
        filterOptions.push(MaintenanceFilterHelper.assetFilterWithGlobalId);
        filterOptions.push(MaintenanceFilterHelper.servicePlanFilter);
        return (
            <MaintenanceFilterForm
                filters={filterOptions}
                selectedFilters={this.state.selectedFilters}
                onFilterSelected={this.onFiltersSelected}
            />
        );
    };

    listTypeContextAction = (action) => {
        if (action === 'filter') {
            return this.filterComponent();
        } else if (action === 'add') {
            this.addClick();
        }
        return true;
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: SERVICE_PLAN_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: SERVICE_PLAN_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        const { classes } = this.props;
        let addServicePlansEl = null;
        if (this.state.isAddEditShown) {
            addServicePlansEl = (
                <AddServicePlans
                    windowTitle={this.state.editMode ? 'Edit Service Plan' : 'Create Service Plan'}
                    isShown={this.state.isAddEditShown}
                    closeAddEdit={this.closeAddEdit}
                    selectedPlan={this.state.selectedPlan}
                    editMode={this.state.editMode}
                />
            );
        }

        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {addServicePlansEl}
                <div className={this.state.isAddEditShown ? classes.hide : classes.show}>
                    {this.state.showListing ?
                        <ListComponent
                            redirectTo={this.props.history.push}
                            tableData={this.state.tableData}
                            loadNextPage={(page, rowsPerPage, order, orderBy) => {
                                const updateUserPref = isUpdatePreference(
                                    this,
                                    { rowsPerPage, order, orderBy },
                                );
                                this.setState({
                                    page, order, orderBy, rowsPerPage,
                                }, () => {
                                    if (updateUserPref) {
                                        saveUserPreference(this, SERVICE_PLAN_LIST_PREF_KEY);
                                    }
                                });
                                this.props.fetchPlanList(
                                    page,
                                    rowsPerPage,
                                    order,
                                    orderBy,
                                    this.state.selectedFilters,
                                );
                            }}
                            rearrangeColumns={this.rearrangeColumns}
                            resetTable={this.props.resetTable}
                            isUpdating={this.props.isUpdating}
                            showHideColumn={this.showHideColumn}
                            sortTableColumn={this.sortTableColumn}
                            listTypeContextAction={this.listTypeContextAction}
                            tableTitle="Service Plans"
                            tableButtons={['Filter List', 'Add Items']}
                            filtersApplied={this.state.selectedFilters.length > 0}
                            clickRow={this.clickRow}
                            order={this.state.order}
                            orderBy={this.state.orderBy}
                            rowsPerPage={this.state.rowsPerPage}
                            showSearch={false}
                            resizableHeader
                        />
                        :
                        <div style={{ height: '100%' }}>
                            <AppLoader type="fullScreen" />
                        </div>
                    }
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    plans: state.planList.plans,
    totalPlans: state.planList.totalPlans,
    isUpdating: state.planList.isUpdating,
});


export default withStyles(styles)(reduxConnect(PlanList, actions, mapStateToProps));
