/* @flow */

import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Divider, Grid, FormGroup, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.deviceList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import AppLoader from '../../components/AppLoader';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import { DEVICE_LIST_PREF_KEY } from './constants.deviceList';
import { getPermissionValue } from '../../helper-classes/utility-functions';
import analytics from '../../analytics';

export type Props = {
    classes : Object,
    isUpdating: boolean,
    fetchDevicesList: Function,
    resetTable: Function,
    location: Object,
    history: {
        push: Function,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    deviceFilters: Object,
    showListing: boolean,
};

const themeStyles = () => ({
    checked: {
        color: '#007aff',
    },
    label: {
        color: '#696969',
    },
});

class DeviceList extends Component<Props, State> {
    deviceFiltersToSend: any;
    search: string;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            showListing: false,
            tableData: {
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'asc',
            orderBy: 'assetName',
            deviceFilters: {
                status: { active: false, inactive: false },
                assetLinked: { linked: false, unlinked: false },
            },
        };
        this.search = '';
        this.deviceFiltersToSend = [];
        this.userPreferences = null;
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, DEVICE_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.device.length > 0) {
            const { device, totalDevice } = nextprops;
            const { tableData } = this.state;
            tableData.listData = device;
            tableData.totalRecords = totalDevice;
            this.setState({ tableData });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }
    }

    getData = () => {
        this.props
            .fetchDevicesList(
                0,
                this.state.rowsPerPage,
                this.state.order,
                this.state.orderBy,
                this.getFilterData(),
            );
        this.setState({ showListing: true });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { serialNumber: 'Serial Number', ...getColumnPreferences('serialNumber', preferences) },
            {
                assetName: 'Asset Name',
                customMarkup: (d) => {
                    if (d.assetName && d.assetId) {
                        return (
                            getPermissionValue('Assets') !== 'None' ?
                                <Tooltip title={d.assetName || ''} disableFocusListener>
                                    <Link
                                        to={`/home?assetId=${d.assetId}&zoom=true`}
                                        style={{ color: 'inherit' }}
                                    >
                                        {d.assetName}
                                    </Link>
                                </Tooltip>
                                : d.assetName
                        );
                    }
                    return '';
                },
                ...getColumnPreferences('assetName', preferences),
            },
            { active: 'Inactive/Active', ...getColumnPreferences('active', preferences) },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, DEVICE_LIST_PREF_KEY);
            }
        });

        this.props.fetchDevicesList(
            rows,
            value,
            order,
            orderBy,
            this.getFilterData(),
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchDevicesList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
        }, () => {
            saveUserPreference(this, DEVICE_LIST_PREF_KEY);
        });
    }

    ListSearch = (order, orderBy, filter) => {
        this.search = filter.search || '';
        this.props.fetchDevicesList(
            filter.pageNumber,
            filter.rowsPerPage,
            order, orderBy,
            this.getFilterData(),
        );
        this.setState({
            order,
            orderBy,
            rowsPerPage: filter.rowsPerPage,
            pageNumber: filter.pageNumber,
        });
    }

    applyExistingFilters = () => {
        this.deviceFiltersToSend = this.getFilterParams(this.state.deviceFilters);
        this.props.fetchDevicesList(
            0,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            this.getFilterData(),
        );
    }

    applyDeviceFilters = (e, key) => {
        const newState = this.state;
        if (newState.deviceFilters[key]
            && newState.deviceFilters[key][e.target.value] !== undefined) {
            newState.deviceFilters[key][e.target.value] = e.target.checked;
            newState.pageNumber = 0;
        }

        this.deviceFiltersToSend = this.getFilterParams(newState.deviceFilters);
        this.setState(newState, () => this.props.fetchDevicesList(
            0,
            newState.rowsPerPage,
            newState.order,
            newState.orderBy,
            this.getFilterData(),
        ));
        const params = {
            feature: 'Admin',
            active: this.state.deviceFilters && this.state.deviceFilters.status &&
                this.state.deviceFilters.status.active,
            inactive: this.state.deviceFilters && this.state.deviceFilters.status &&
                this.state.deviceFilters.status.inactive,
            linked: this.state.deviceFilters && this.state.deviceFilters.assetLinked &&
                this.state.deviceFilters.assetLinked.linked,
            unlinked: this.state.deviceFilters && this.state.deviceFilters.assetLinked &&
                this.state.deviceFilters.assetLinked.unlinked,
        };
        analytics.track('DEVICES_FILTER', params);
    }

    getFilterData = () => ({
        deviceFiltersToSend: this.deviceFiltersToSend,
        search: this.search || '',
    })

    clearFilters = () => {
        const newState = this.state;
        let callFetchDevicesList = false;
        Object.keys(newState.deviceFilters).forEach(group => {
            Object.keys(newState.deviceFilters[group]).forEach((filterKey) => {
                if (newState.deviceFilters[group][filterKey]) callFetchDevicesList = true;
                newState.deviceFilters[group][filterKey] = false;
            });
        });

        newState.pageNumber = 0;
        this.deviceFiltersToSend = this.getFilterParams(newState.deviceFilters);
        this.setState(newState);

        if (callFetchDevicesList) {
            this.props.fetchDevicesList(
                this.state.pageNumber,
                this.state.rowsPerPage,
                this.state.order,
                this.state.orderBy,
                this.getFilterData(),
            );
        }
    }

    refreshList = (page) => {
        this.props.fetchDevicesList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
            { search: this.search },
            this.getFilterData(),
        );
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
            this.applyExistingFilters();
        } else if (action === 'filter') {
            return this.renderDeviceFilters();
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        }
        return true;
    }

    getFilterParams = (deviceFilters) => {
        this.deviceFiltersToSend = [];
        const statusFilterVals = [];
        const assetLinkedFilterVals = [];

        Object.keys(deviceFilters).forEach(group => {
            Object.keys(deviceFilters[group]).forEach((filterKey) => {
                if (deviceFilters[group][filterKey]) {
                    if (group === 'status') {
                        statusFilterVals.push((filterKey === 'active'));
                    } else {
                        assetLinkedFilterVals.push((filterKey === 'linked' ? 'yes' : 'no'));
                    }
                }
            });
        });

        if (statusFilterVals.length > 0) this.deviceFiltersToSend.push(['active', `eq:${statusFilterVals.join(',')}`]);

        if (assetLinkedFilterVals.length > 0) this.deviceFiltersToSend.push(['assetName', `exists:${assetLinkedFilterVals.join(',')}`]);

        return this.deviceFiltersToSend;
    }

    renderDeviceFilters = () => {
        const { classes } = this.props;
        const { deviceFilters } = this.state;

        const styles = {
            button: { flex: 'auto', opacity: '.87', color: '#007aff' },
            subheading: {
                fontSize: 15,
                opacity: '.87',
                padding: '22px 20px',
            },
            title: {
                flex: 'auto',
                fontSize: '20px',
                opacity: '.87',
                padding: 20,
            },
            filterCheckboxChecked: {
                color: '#007aff',
                marginLeft: '40px',
            },
            filterCheckboxUnchecked: {
                color: '#696969',
                marginLeft: '40px',
            },
        };

        return (
            <div style={{ padding: 20, flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" id="filterTitle" style={styles.title}>Filters</Typography>
                    <Button
                        color="primary"
                        style={styles.button}
                        onClick={this.clearFilters}
                    >
                        CLEAR
                    </Button>
                </div>
                <Divider />
                <Grid item xs={7} key="deviceStatus">
                    <FormGroup>
                        <Typography variant="body1" id="status" style={styles.subheading}>Status</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="active"
                                    value="active"
                                    style={deviceFilters.status.active ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="activeStatus"
                            label="Active"
                            checked={deviceFilters.status.active}
                            onChange={e => this.applyDeviceFilters(e, 'status')}
                            classes={{ label: `${deviceFilters.status.active ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="inactive"
                                    value="inactive"
                                    style={deviceFilters.status.inactive ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="inactiveStatus"
                            label="Inactive"
                            checked={deviceFilters.status.inactive}
                            onChange={e => this.applyDeviceFilters(e, 'status')}
                            classes={{ label: `${deviceFilters.status.inactive ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
                <Grid item xs={7} key="assetAssoication">
                    <FormGroup>
                        <Typography variant="body1" id="assetLinked" style={styles.subheading}>Asset Association</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="assetLinked"
                                    value="linked"
                                    style={deviceFilters.assetLinked.linked ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="linked"
                            label="Linked"
                            checked={deviceFilters.assetLinked.linked}
                            onChange={e => this.applyDeviceFilters(e, 'assetLinked')}
                            classes={{ label: `${deviceFilters.assetLinked.linked ? classes.checked : classes.label}` }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key="assetUnlinked"
                                    value="unlinked"
                                    style={deviceFilters.assetLinked.unlinked ?
                                        styles.filterCheckboxChecked :
                                        styles.filterCheckboxUnchecked}
                                />
                            }
                            key="unlinked"
                            label="Unlinked"
                            checked={deviceFilters.assetLinked.unlinked}
                            onChange={e => this.applyDeviceFilters(e, 'assetLinked')}
                            classes={{ label: `${deviceFilters.assetLinked.unlinked ? classes.checked : classes.label}` }}
                        />
                    </FormGroup>
                </Grid>
                <Divider />
            </div>
        );
    }

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: DEVICE_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: DEVICE_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        return (
            <MiniDrawer
                redirectTo={this.props.history.push}
            >
                {this.state.showListing ?
                    <ListComponent
                        redirectTo={this.props.history.push}
                        tableData={this.state.tableData}
                        loadNextPage={this.loadNextPage}
                        isUpdating={this.props.isUpdating}
                        rearrangeColumns={this.rearrangeColumns}
                        resetTable={this.props.resetTable}
                        showHideColumn={this.showHideColumn}
                        sortTableColumn={this.sortTableColumn}
                        listTypeContextAction={this.listTypeContextAction}
                        tableTitle="Devices"
                        filtersApplied={this.deviceFiltersToSend.length > 0}
                        ListSearch={this.ListSearch}
                        tableButtons={['Refresh', /* 'Map', */ 'Filter List']}
                        resizableHeader
                        orderBy={this.state.orderBy}
                        order={this.state.order}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                    :
                    <div style={{ height: '100%' }}>
                        <AppLoader type="fullScreen" />
                    </div>
                }
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    device: state.deviceList.devices,
    totalDevice: state.deviceList.totalDevices,
    isUpdating: state.deviceList.isUpdating,
});

export default withStyles(themeStyles)(reduxConnect(DeviceList, actions, mapStateToProps));
