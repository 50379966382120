/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_TRIPS_DETAILS, FETCH_TRIPS_DIRECTION } from './constants.trips';
import { fetchTripsDetailsSuccess, fetchTripsDetailsError, fetchTripsDirectionSuccess, fetchTripsDirectionError } from './actions.trips';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function,
};

let tripsData = {};
const limit = 50;
const offset = 0;
export const getTrips =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_TRIPS_DETAILS)
            .mergeMap((actions) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                const eventsLimit = 200;
                const { id, params } = actions.payload;
                return getJSON(`${config.get('TRIPS_API_BASE_URL')}/trips/${id}/events?limit=${eventsLimit}&offset=${offset}&${params}`, headers)
                    .mergeMap((res) => {
                        tripsData = res;
                        return of(fetchTripsDetailsSuccess(tripsData));
                    }).catch(error => of(fetchTripsDetailsError(error)));
            });
let tripsDirection = {};
export const getTripsDirection =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_TRIPS_DIRECTION)
            .mergeMap(() => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                return getJSON(`${config.get('TRIPS_API_BASE_URL')}/trips/3511087?limit=${limit}&offset=${offset}`, headers)
                    .mergeMap((res) => {
                        tripsDirection = res;
                        return of(fetchTripsDirectionSuccess(tripsDirection));
                    }).catch(error => of(fetchTripsDirectionError(error)));
            });
export default combineEpics(getTripsDirection, getTrips);
