/* @flow */
import React, { Component } from 'react';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import {
    Paper,
    Typography,
    Toolbar,
    IconButton,
    FormControl,
    FormGroup,
    Divider,
    Grid,
    Button,
    MenuItem,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import back from '../../assets/icons/back.png';
import AppLoader from '../AppLoader';
import {
    getUnitsOfMeasure,
    getTimeZones,
    getPermissionValue,
    hasFeature,
    removeMultipleSpacesBetweenChars,
    getUsername,
    isEmpty,
} from '../../helper-classes/utility-functions';
import { USER_ROLE_ARRAY } from '../../containers/UserRole/AddUserRole/constants.userRole';
import { FULL_ACCESS } from '../../containers/User/AddUser/constants.user';
import { FIELD_MAX_LENGTH_255, USERNAME_MAX_LENGTH } from '../../constants/Config';

const styles = () => ({
    root: {
        padding: 16,
        display: 'flex',
    },
    toolbar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    formControl: {
        width: '90%',
    },
    card: {
        backgroundColor: '#999b9e',
    },
    app: {
        paddingTop: 16,
        paddingLeft: 17,
        paddingRight: 23,
    },
    button: {
        color: '#007aff',
    },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 10,
        marginLeft: -12,
    },
    requiredField: {
        fontSize: 14,
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    formValidator: {
        padding: '0 0 0 30px',
    },
    titleWrap: {
        lineHeight: 2,
    },
    listItemText: {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.75',
        letterSpacing: '0.00938em',
    },
    msgWrap: {
        width: 490.7,
        height: 15,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.5px',
        position: 'absolute',
        right: 20,
        top: 20,
    },
    divider: {
        borderRadius: 2,
    },
    listItem: {
        padding: 0,
    },
    listItemCheckbox: {
        padding: '12px 12px 12px 4px',
    },
    sideBySide: {
        display: 'flex',
        overflow: 'hidden',
    },
    textField: {
        width: '327px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '& label': {
            lineHeight: '1.1em',
        },
        '& div > input': {
            height: '1.25em',
        },
    },
    dense: {
        marginTop: 2,
    },
    properties: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    propertyTextField: {
        flex: 'auto',
        margin: '0 12px 0 0',
        maxWidth: 170,
    },
    dropdownContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10px 0',
        justifyContent: 'space-between',
    },
    dropdown: {
        flex: 'auto',
        maxWidth: 250,
        margin: '6px 20px 0 0',
    },
    checkbox: {
        display: 'flex',
        width: 300,
    },
    container: {
        display: 'flex',
    },
    roleAccessSection: {
        width: '90%',
        marginTop: '6px',
        fontSize: 16,
    },
    listRoot: {
        position: 'relative',
        overflow: 'auto',
        maxHeight: 180,
    },
    timeZoneDropdown: {
        fontSize: 20,
    },
    timeZoneControl: {
        width: '48.5%',
    },
    timeDropdownContainer: {
        marginTop: '50px',
    },
    errorLabel: {
        color: '#f44336',
        fontSize: 22,
        margin: '8px 0px 12px 0px',
    },
    userAccessLabel: {
        fontSize: 22,
    },
    errorTest: {
        color: '#f44336',
        fontSize: '1rem',
        textAlign: 'left',
        marginTop: '8px',
        marginBottom: '-9px',
    },
    mainContainer: {
        margin: '17px 16px !important',
        display: 'block !important',
    },
    lightTooltip: {
        borderColor: '#000000',
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
    },
    passwordMsgDiv: {
        fontSize: '12px',
        color: '#787877',
    },
    passwordCard: {
        paddingRight: '12px',
        width: '150%',
    },
});

export type Props = {
    classes: Object,
    createUser: Function,
    editUser: Function,
    deleteUser: Function,
    isUpdating: boolean,
    isEdit: boolean,
    user: {
        name: string,
        description: string,
        securityUniversesJSON: any,
        simplifiedPermissions: any,
        simplifiedRoles: Array<{
            brand: string,
            code: string,
            id: string,
            name: string,
        }>,
        firstName: string,
        lastName: string,
        email: string,
        username: string,
        phone: string,
        comments: string,
        tz: string,
        units: string,
    },
    userRoles: Array<{
        id: number,
        name: string,
        permissionsJSON: Object,
    }>,
    userAccess: Array<{
        id: number,
        name: string,
    }>,
};

export type State = {
    firstName: string,
    lastName: string,
    email: string,
    userName: string,
    phoneNumber: string,
    description: string,
    selectUserRole: string,
    selectedUserAccess: Array<any>,
    selectedUserAccessName: Array<any>,
    selectedTimeZone: string,
    selectedUnits: string,
    user: Object,
    resendInvitation: boolean,
    isSelectedUserAccess: boolean,
    userAccess: Array<Object>,
};

class addUser extends Component<Props, State> {
    permissionsJSON: Array<Object>;
    timeZones: Array<Object>;
    unitOfMeasure: Array<Object>;
    isNotLoggedInUser: boolean;
    isUserLoaded: boolean;
    page: number;
    scrollPosition: number;
    totalPage: number;
    pageSize: number;

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            userName: '',
            phoneNumber: '',
            description: '',
            selectUserRole: '',
            selectedUserAccess: [],
            selectedUserAccessName: [],
            selectedTimeZone: '',
            selectedUnits: '',
            user: {},
            resendInvitation: false,
            isSelectedUserAccess: true,
            userAccess: [],
        };

        this.permissionsJSON = [];
        this.timeZones = getTimeZones();
        this.unitOfMeasure = getUnitsOfMeasure();
        this.isUserLoaded = false;
        this.page = 0;
        this.scrollPosition = 0;
        this.totalPage = 0;
        this.pageSize = 30;
    }

    UNSAFE_componentWillMount() {
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') {
                return false;
            } else if (typeof value === 'number' && value === 0) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('fieldLengthCheck', (value) => {
            if (typeof value === 'string' && value.length > FIELD_MAX_LENGTH_255) {
                // eslint-disable-next-line
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('isPhone', (value) => {
            if (typeof value === 'string' && value.length > 0) {
                // eslint-disable-next-line
                return value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g);
            }
            return true;
        });

        ValidatorForm.addValidationRule('isUserNameLength', value => !(value.length > USERNAME_MAX_LENGTH));
    }

    componentDidMount() {
        const listArea = document.getElementById('userList');
        const multiplier = (window.outerWidth / window.innerWidth > 1) ?
            (window.outerWidth / window.innerWidth) : 1;

        if (listArea) {
            listArea.onscroll = () => {
                if (this.scrollPosition < listArea.scrollTop) {
                    if (Math.ceil(multiplier * listArea.scrollTop) + listArea.clientHeight
                        >= listArea.scrollHeight) {
                        this.scrollPosition = listArea.scrollTop - 1;
                        if (this.page < this.totalPage - 1) {
                            this.page = this.page + 1;
                            listArea.scrollLeft = 0;
                            listArea.scrollTop = listArea.clientHeight / 2;
                        }
                        this.updateList();
                    }
                } else if (listArea.scrollTop <= 0 && this.page > 0) {
                    this.scrollPosition = listArea.scrollTop;
                    if (this.page >= 0) {
                        this.page = this.page - 1;
                        this.updateList();
                        listArea.scrollLeft = 0;
                        listArea.scrollTop = listArea.clientHeight + 1000;
                    }
                }
            };
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isEdit && nextProps.user && typeof nextProps.user === 'object' && Object.keys(nextProps.user).length > 0) {
            this.isUserLoaded = true;
            this.updateUser(nextProps.user, nextProps.userAccess);
            const userName = getUsername();
            this.isNotLoggedInUser = true;

            if (userName && this.state.userName &&
                (userName.toUpperCase() === this.state.userName.toUpperCase())) {
                this.isNotLoggedInUser = false;
            }
        }

        if (nextProps.userAccess && nextProps.userAccess.length > 0) {
            this.totalPage = Math.ceil(nextProps.userAccess.length / this.pageSize);
            this.setState({ userAccess: nextProps.userAccess.slice(0, this.pageSize) });
            this.page = 0;
            this.scrollPosition = 0;
        }
    }

    updateList = () => {
        if (this.page > this.totalPage - 1) return;
        this.setState({
            userAccess:
            this.props.userAccess
                .slice(this.page * this.pageSize, ((this.page + 1) * this.pageSize) + 5),
        });
    };

    updateUser = (selectedUser, allUserAccess) => {
        if (this.isUserLoaded) {
            const userAccess = selectedUser && selectedUser.securityUniversesJSON ? JSON.parse(selectedUser.securityUniversesJSON.replace(/[.]*/gi, '')) : [];
            let {
                firstName,
                lastName,
                email,
                userName,
                phoneNumber,
                description,
                selectUserRole,
                selectedTimeZone,
                selectedUnits,
                user,
                resendInvitation,
                isSelectedUserAccess,
            } = this.state;

            firstName = selectedUser.firstName || firstName;
            lastName = selectedUser.lastName || lastName;
            email = selectedUser.email || email;
            userName = selectedUser.username || userName;
            phoneNumber = selectedUser.phone || phoneNumber;
            description = selectedUser.comments || description;
            selectedTimeZone = selectedUser.tz || selectedTimeZone;
            selectedUnits = selectedUser.units || selectedUnits;

            if (selectedUser.simplifiedRoles) {
                const excludedRoles = ['MASTER_USER', 'ELD_MANAGER'];
                const filteredRoles = selectedUser.simplifiedRoles
                    .filter(e => excludedRoles.indexOf(e.name) === -1);
                if (filteredRoles.length > 0) selectUserRole = filteredRoles[0].id;
            }

            this.setUserRoleInfo(selectedUser.simplifiedPermissions);
            const { selectedUserAccess, selectedUserAccessName } = this.state;
            isSelectedUserAccess = false;

            // Conditionally set selectedUserAccess
            if (isEmpty(selectedUserAccess) && userAccess.length > 0 && !isEmpty(allUserAccess)) {
                userAccess.map((r) => {
                    if (r.name === FULL_ACCESS) {
                        // select All other access if Full Access is selected
                        allUserAccess.forEach((item) => {
                            selectedUserAccess.push(item.id);
                            selectedUserAccessName.push(item.name);
                        });
                    } else {
                        selectedUserAccess.push(r.id);
                        selectedUserAccessName.push(r.name);
                    }
                    return true;
                });
                isSelectedUserAccess = true;
            } else if (!isEmpty(selectedUserAccess)) {
                // If already selectedUserAccess
                isSelectedUserAccess = true;
            }

            resendInvitation = false;
            user = (selectedUser);
            this.setState({
                firstName,
                lastName,
                email,
                userName,
                phoneNumber,
                description,
                selectUserRole,
                selectedUserAccess,
                selectedUserAccessName,
                selectedTimeZone,
                selectedUnits,
                user,
                resendInvitation,
                isSelectedUserAccess,
            });
        }
    }

    applyCheckbox = name => event => this.setState({ [name]: event.target.checked });

    handleChange = name => (event) => {
        let input = event.target.value;
        if (name === 'userName') {
            input = removeMultipleSpacesBetweenChars(event.target.value);
        }
        this.setState({ [name]: input });
    };

    handleSelectChange = (event) => {
        if (event.target.name === 'selectUserRole') {
            this.setState({ selectUserRole: event.target.value });
            if (this.props.userRoles) {
                let userRole = this.props.userRoles.filter(d => d.id === event.target.value);
                if (userRole && userRole.length > 0) {
                    userRole = userRole.pop();
                    const permissionsJSON = userRole && userRole.permissionsJSON ? JSON.parse(userRole.permissionsJSON.replace(/[.]*/gi, '')) : [];
                    this.setUserRoleInfo(permissionsJSON);
                }
            }
        } else this.setState({ [event.target.name]: event.target.value });
    };

    setUserRoleInfo = (permissions: Array<Object>) => {
        const permissionsJSON = [];
        if (permissions && permissions.length > 0) {
            let index = 0;

            USER_ROLE_ARRAY.forEach((r) => {
                if (r.disabled !== true) {
                    const i = permissions.findIndex(p =>
                        ((p.permissionCode === r.roleCode) || (p.code === r.roleCode)));

                    if ((i > -1) &&
                        (!r.featureCheck || (r.featureCheck && r.featureCheck.split(', ').some(rr => hasFeature(rr))))) {
                        index += 1;
                        permissionsJSON.push({
                            order: index,
                            code: r.roleCode,
                            value: permissions[i].option,
                            key: r.title,
                        });
                    }
                }
            });

            this.permissionsJSON = permissionsJSON;
        }
    };

    saveUser = () => {
        let securityUniverses = [];
        let securityUniversesName = [];
        // If Full Access is selected, save only 'Full Access'
        if (this.isFullAccessSelected()) {
            const fullAccessNameIndex = this.state.selectedUserAccessName.indexOf(FULL_ACCESS);
            const fullAccessId = this.state.selectedUserAccess[fullAccessNameIndex];
            securityUniverses.push(fullAccessId);
            securityUniversesName.push(FULL_ACCESS);
        } else {
            securityUniverses = this.state.selectedUserAccess;
            securityUniversesName = this.state.selectedUserAccessName;
        }
        securityUniverses = securityUniverses.filter((item, pos) =>
            securityUniverses.indexOf(item) === pos);
        const securityUniversesJSON = securityUniverses.length > 0 ? securityUniverses.map(r => `{"id":${r}}`) : '';
        securityUniversesName = securityUniversesName.filter((item, pos) =>
            securityUniversesName.indexOf(item) === pos);
        const securityUniversesNameJSON = securityUniversesName.length > 0 ? securityUniversesName.map(r => `{"name":${r}}`) : '';
        this.setState({ isSelectedUserAccess: (securityUniverses.length > 0) });

        if (this.state.isSelectedUserAccess && securityUniverses.length > 0) {
            if (this.props.isEdit) {
                const updateUser = this.state.user;
                updateUser.firstName = this.state.firstName;
                updateUser.lastName = this.state.lastName;
                updateUser.email = this.state.email;
                updateUser.username = this.state.userName;
                updateUser.phone = this.state.phoneNumber;
                updateUser.comments = this.state.description;
                updateUser.tz = this.state.selectedTimeZone;
                updateUser.units = (updateUser.units === '') ? 'miles' : this.state.selectedUnits;
                updateUser.resendInvitation = this.state.resendInvitation;
                updateUser.rolesJSON = this.state.selectUserRole ? `[{"id":${this.state.selectUserRole}}]` : '';
                updateUser.roleJsonDisplay = this.permissionsJSON;
                updateUser.securityUniversesJSON = `[${securityUniversesJSON.toString().replace('}"', '}')}]`;
                updateUser.securityUniversesJSONDisplay = `[${securityUniversesNameJSON.toString().replace('}"', '}')}]`;
                this.props.editUser(updateUser);
            } else {
                const requestBody = {
                    account: '',
                    active: '',
                    comments: this.state.description,
                    createMode: 'Invitation',
                    currentPassword: '',
                    displayName: '',
                    email: this.state.email,
                    extension: '',
                    externalId: '',
                    firstName: this.state.firstName,
                    fullName: '',
                    hometown: '',
                    internalId: '',
                    language: '',
                    lastLogin: '',
                    lastName: this.state.lastName,
                    loginConfirmed: '',
                    loginEnabled: '',
                    masterUser: '',
                    mobilePhone: '',
                    password: this.state.password,
                    permissionsJSON: '',
                    phone: this.state.phoneNumber,
                    region: '',
                    resendInvitation: false,
                    rolesDisplay: '',
                    rolesFlattened: '',
                    rolesJSON: this.state.selectUserRole ? `[{"id":${this.state.selectUserRole}}]` : '',
                    roleJsonDisplay: this.permissionsJSON,
                    securityUniversesFlattened: '',
                    securityUniversesDisplay: '',
                    securityUniversesJSON: `[${securityUniversesJSON.toString().replace('}"', '}')}]`,
                    securityUniversesJSONDisplay: `[${securityUniversesNameJSON.toString().replace('}"', '}')}]`,
                    simplifiedPermissions: '',
                    simplifiedRoles: '',
                    statusId: '',
                    statusLabel: '',
                    tags: '',
                    timeFormat: '',
                    tz: this.state.selectedTimeZone,
                    units: this.state.selectedUnits ? this.state.selectedUnits : 'miles',
                    userErrors: '',
                    userId: '',
                    userLabel: '',
                    username: this.state.userName.trim(),
                    version: '',
                    visible: '',
                };
                this.props.createUser(requestBody);
            }
        }
    };

    deleteUser = () => this.props.deleteUser(this.state.user.id);

    handleToggle = (id, value) => {
        const { selectedUserAccess, selectedUserAccessName } = this.state;
        let newChecked = [...selectedUserAccess];
        let newCheckedValue = [...selectedUserAccessName];
        let currentIndex = selectedUserAccess.indexOf(id);

        if (value === FULL_ACCESS) {
            if (currentIndex === -1) {
                newChecked = [];
                newCheckedValue = [];
                this.props.userAccess.forEach((item) => {
                    newChecked.push(item.id);
                    newCheckedValue.push(item.name);
                });
            } else {
                newChecked = [];
                newCheckedValue = [];
            }
        } else {
            currentIndex = selectedUserAccess.indexOf(id);
            if (currentIndex !== -1) {
                newChecked.splice(currentIndex, 1);
                newCheckedValue.splice(currentIndex, 1);
                if (this.isFullAccessSelected()) {
                    newChecked.splice(this.state.selectedUserAccessName.indexOf(FULL_ACCESS), 1);
                    newCheckedValue.splice(this.state.selectedUserAccessName
                        .indexOf(FULL_ACCESS), 1);
                }
            } else {
                newChecked.push(id);
                newCheckedValue.push(value);
            }
        }

        this.setState({
            selectedUserAccess: newChecked,
            selectedUserAccessName: newCheckedValue,
            isSelectedUserAccess: (newChecked.length > 0),
        });
    };

    isFullAccessSelected = () => (this.state.selectedUserAccessName.indexOf(FULL_ACCESS) > -1)

    getChecked = id => (this.state.selectedUserAccess.indexOf(id) > -1)

    render() {
        const {
            classes, isUpdating, isEdit, userRoles,
        } = this.props;

        const {
            firstName = '',
            lastName = '',
            email = '',
            userName = '',
            phoneNumber = '',
            description = '',
            selectUserRole = '',
            selectedTimeZone = '',
            selectedUnits = '',
            resendInvitation = false,
            isSelectedUserAccess = false,
            userAccess,
        } = this.state;

        return (
            <div className={classes.mainContainer}>
                <Paper>
                    <Toolbar className={classes.toolbar}>
                        <span className={classes.backButton}>
                            <IconButton onClick={() => window.history.back()} size="large">
                                <img src={back} alt="backArrow" />
                            </IconButton>
                        </span>
                        <Typography variant="h6" color="inherit" className={classes.titleWrap}>
                            <span>{isEdit ? 'Edit' : 'Add'} User</span>
                        </Typography>
                        <Typography variant="h6" color="inherit" className={classes.msgWrap}>
                            <span>{isEdit ? '' : 'A welcome email will be sent automatically when the new user is added.'} </span>
                        </Typography>
                    </Toolbar>
                    <ValidatorForm autoComplete="off" onSubmit={this.saveUser} className={classes.formValidator}>
                        <Grid container className={classes.container}>
                            <Grid item xs={12} md={8} lg={6}>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                        <TextValidator
                                            id="firstName"
                                            label="First Name*"
                                            className={classes.textField}
                                            name="firstName"
                                            value={firstName}
                                            onChange={this.handleChange('firstName')}
                                            margin="dense"
                                            validators={['isEmpty']}
                                            errorMessages={['this field is required']}
                                            autoComplete="none"
                                        />
                                        <TextValidator
                                            id="lastName"
                                            label="Last Name*"
                                            key="lastName"
                                            className={classNames(classes.textField, classes.dense)}
                                            name="lastName"
                                            value={lastName}
                                            onChange={this.handleChange('lastName')}
                                            margin="dense"
                                            validators={['isEmpty']}
                                            errorMessages={['this field is required']}
                                            autoComplete="none"
                                        />
                                        <TextValidator
                                            id="email"
                                            label="Email*"
                                            className={classNames(classes.textField, classes.dense)}
                                            name="email"
                                            value={email}
                                            onChange={this.handleChange('email')}
                                            margin="dense"
                                            validators={['isEmpty', 'isEmail', 'fieldLengthCheck']}
                                            errorMessages={['this field is required', 'Email is not valid', '255 char is max allowed.']}
                                            autoComplete="none"
                                        />
                                        <TextValidator
                                            id="userName"
                                            label="User Name*"
                                            className={classNames(classes.textField, classes.dense)}
                                            name="userName"
                                            value={userName}
                                            onChange={this.handleChange('userName')}
                                            margin="dense"
                                            disabled={isEdit}
                                            validators={['isEmpty', 'isUserNameLength']}
                                            errorMessages={['this field is required', `${USERNAME_MAX_LENGTH} char is max allowed.`]}
                                            autoComplete="none"
                                        />
                                        <TextValidator
                                            id="phoneNumber"
                                            label="Phone"
                                            className={classNames(classes.textField, classes.dense)}
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={this.handleChange('phoneNumber')}
                                            margin="dense"
                                            validators={['isPhone']}
                                            errorMessages={['Phone Number is not valid']}
                                        />
                                        <TextValidator
                                            id="description"
                                            label="Comments"
                                            className={classNames(classes.textField, classes.dense)}
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange('description')}
                                            margin="dense"
                                            validators={['maxStringLength:250']}
                                            errorMessages={['Maximum 250 characters is allowed']}
                                        />
                                        <div className={`${classes.sideBySide} ${classes.timeDropdownContainer}`}>
                                            <FormControl className={classes.timeZoneControl}>
                                                <SelectValidator
                                                    containerProps={{ className:
                                                        classes.dropdownContainer }}
                                                    sx={{ minWidth: '180px' }}
                                                    className={classes.dropdown}
                                                    onChange={this.handleSelectChange}
                                                    value={selectedTimeZone}
                                                    name="selectedTimeZone"
                                                    select
                                                    validators={['required', 'isEmpty']}
                                                    errorMessages={['this field is required']}
                                                    label="Time Zone*"
                                                >
                                                    <MenuItem value="">Select Time Zone</MenuItem>
                                                    {this.timeZones.length > 0 ?
                                                        this.timeZones.map(r => <MenuItem key={r.value} value={r.value}>{r.name}</MenuItem>) : ''};
                                                </SelectValidator>
                                            </FormControl>
                                            <FormControl className={classes.timeZoneControl}>
                                                <SelectValidator
                                                    containerProps={{ className:
                                                        classes.dropdownContainer }}
                                                    sx={{ minWidth: '180px' }}
                                                    className={classes.dropdown}
                                                    onChange={this.handleSelectChange}
                                                    value={selectedUnits}
                                                    name="selectedUnits"
                                                    label="Unit of Measure"
                                                >
                                                    <MenuItem value="">Select Units of Measure</MenuItem>
                                                    {this.unitOfMeasure.length > 0 ?
                                                        this.unitOfMeasure.map(r => <MenuItem key={r.value} value={r.value}>{r.name}</MenuItem>) : '' };
                                                </SelectValidator>
                                            </FormControl>
                                        </div>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                                <div className={`${classes.dense} ${classes.roleAccessSection}`}>
                                    <div
                                        style={{
                                            minHeight: 300,
                                            width: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            marginTop: -18,
                                        }}
                                    >
                                        <SelectValidator
                                            containerProps={{ className:
                                                classes.dropdownContainer }}
                                            sx={{ minWidth: '250px' }}
                                            className={`${classes.dropdown}`}
                                            onChange={this.handleSelectChange}
                                            value={selectUserRole}
                                            name="selectUserRole"
                                            select
                                            validators={['required', 'isEmpty']}
                                            errorMessages={['this field is required']}
                                            label="User Role*"
                                        >
                                            <MenuItem value="" data-qa="">Select User Role</MenuItem>
                                            {userRoles.length > 0 ? userRoles.map(r =>
                                                <MenuItem key={r.id} value={r.id} data-qa={r.name}>{r.name}</MenuItem>) : '' };
                                            {isUpdating &&
                                                <MenuItem value="">
                                                    <AppLoader
                                                        loaderStyle={styles.loader}
                                                        height={20}
                                                        width={3}
                                                    />
                                                </MenuItem>
                                            }
                                        </SelectValidator>
                                        {selectUserRole &&
                                            <Typography component="div">
                                                {(this.permissionsJSON &&
                                                this.permissionsJSON.length > 0) &&
                                                    this.permissionsJSON.map(d => (
                                                        <div style={{ display: 'flex' }} key={d.key}>
                                                            <div style={{ width: 400 }}>
                                                                {d.key}
                                                            </div>:
                                                            <div style={{ flex: 'auto', paddingLeft: 5 }}>{d.key === 'Compliance Section' ? d.value.replace('Manager', 'Modify') : d.value}</div>
                                                        </div>))
                                                }
                                            </Typography>
                                        }
                                    </div>
                                    <div style={{ minHeight: 300, minWidth: 200, marginTop: 22 }} key="userAccess">
                                        <InputLabel shrink htmlFor="age-native-label-placeholder" className={classes.userAccessLabel}>
                                            {isSelectedUserAccess ? 'User Access*' : <p className={classes.errorLabel}>User Access*</p>}
                                            {isSelectedUserAccess ? '' : <p className={classes.errorTest}>this field is required</p>}
                                        </InputLabel>
                                        <List className={classes.listRoot} id="userList" key="userAccessList">
                                            {this.props.userAccess.length > 0 ?
                                                userAccess.map(r => (
                                                    <ListItem
                                                        key={r.id}
                                                        onClick={() => this.handleToggle(
                                                            r.id,
                                                            r.name,
                                                        )}
                                                        className={`${classes.dense} ${classes.listItem}`}
                                                        data-qa={r.name}
                                                    >
                                                        <Checkbox
                                                            checked={this.getChecked(r.id)}
                                                            color="primary"
                                                            onChange={() => this.handleToggle(
                                                                r.id,
                                                                r.name,
                                                            )}
                                                            className={classes.listItemCheckbox}
                                                            disableRipple
                                                        />
                                                        <ListItemText
                                                            primary={r.name}
                                                            disableTypography
                                                            className={classes.listItemText}
                                                        />
                                                    </ListItem>
                                                )) :
                                                <ListItemText
                                                    primary="Select User Access"
                                                    disableTypography
                                                    className={classes.listItemText}
                                                />}
                                            {isUpdating &&
                                                <ListItem value="">
                                                    <AppLoader
                                                        loaderStyle={styles.loader}
                                                        height={20}
                                                        width={3}
                                                    />
                                                </ListItem>
                                            }
                                        </List>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Divider className={classes.divider} />
                            <Toolbar>
                                <Grid item>
                                    <div className={classes.requiredField}>
                                        * Required Fields
                                    </div>
                                </Grid>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item>
                                        { isEdit &&
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="resendInvitation"
                                                        value="resendInvitation"
                                                        checked={resendInvitation}
                                                        onChange={this.applyCheckbox('resendInvitation')}
                                                        color="primary"
                                                    />
                                                }
                                                label="Resend Invitation"
                                            />
                                        }
                                        <Button
                                            color="primary"
                                            onClick={() => { window.history.back(); }}
                                        >
                                            Cancel
                                        </Button>
                                        {this.isNotLoggedInUser &&
                                        <Button
                                            color="primary"
                                            onClick={() => { this.deleteUser(); }}
                                            disabled={getPermissionValue('Admin') !== 'Modify'}
                                        >
                                            Delete
                                        </Button>
                                        }
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={getPermissionValue('Admin') !== 'Modify'}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </Grid>
                    </ValidatorForm>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(addUser);
