/* @flow */
import { APP_NOTIFICATION_HANDLER, ADD_CARD_NOTIFICATION, REMOVE_CARD_NOTIFICATION, CLEAR_NOTIFICATION, NOTIFICATION_PULL_HANDLER } from './constants.appNotifications';

type stateType = {
  AppNotificationHandler: Array<Object>,
}

type actionType = {
  type: string,
  payload :{
      response:{},
      data:Array<Object>,
      constructor:Function,
  }
}

export const initialState = {
    AppNotificationHandler: [{
        type: 'nCard',
        isOpen: false,
        count: 0,
        message: [],
        nConfig: {},
        overrideConfig: {},
    }],
};

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case APP_NOTIFICATION_HANDLER: {
        const tempAry = state.AppNotificationHandler;
        let updatedNotificationAry = action.payload.data.map((nObj) => {
            const i = tempAry.findIndex(nItem => ((nObj.type === 'nCard') ? (nObj.type === nItem.type) : ((nObj.type === nItem.type) && nObj.overrideConfig && nItem.overrideConfig && (nObj.overrideConfig.key === nItem.overrideConfig.key))));
            let tempObj = {};
            if (i > -1) [tempObj] = tempAry.splice(i, 1);
            if (nObj.type === 'nCard' && nObj.isOpen && Object.keys(tempObj).length > 0) {
                tempObj.count = 0;
            }

            return ({ ...tempObj, ...nObj });
        });

        if (tempAry.length > 0) updatedNotificationAry = updatedNotificationAry.concat(tempAry);
        return {
            ...state,
            AppNotificationHandler: updatedNotificationAry,
        };
    }
    case ADD_CARD_NOTIFICATION: {
        const updatedDataAry = state.AppNotificationHandler;
        const i = updatedDataAry.findIndex(nItem => (nItem.type === 'nCard'));
        const tempMsgAry = updatedDataAry[i].message;
        let updatedMsgAry = action.payload.data.map((n) => {
            const index = tempMsgAry.findIndex(d => ((n.id === d.id) && (n.title === d.title)
                && (n.status === d.status) && (n.statusDate === d.statusDate)));
            let tempObj = {};
            if (index > -1) [tempObj] = tempMsgAry.splice(index, 1);
            return ({ ...tempObj, ...n });
        });

        if (tempMsgAry.length > 0) updatedMsgAry = updatedMsgAry.concat(tempMsgAry);
        updatedDataAry[i].message = updatedMsgAry;
        if (!updatedDataAry[i].isOpen) updatedDataAry[i].count += action.payload.data.length;

        return {
            ...state,
            AppNotificationHandler: updatedDataAry,
        };
    }
    case REMOVE_CARD_NOTIFICATION: {
        const updatedDataAry = state.AppNotificationHandler;
        const i = updatedDataAry.findIndex(nItem => (nItem.type === 'nCard'));
        const tempMsgAry = updatedDataAry[i].message;
        action.payload.data.forEach((n) => {
            const index = tempMsgAry.findIndex(d =>
                ((n.nCardIDToRemove === d.id)
                    && (n.titleToRemove === d.title) && (n.statusToRemove === d.status)
                    && (n.skipDate || (n.statusDateToRemove === d.statusDate))));
            if (index > -1) tempMsgAry.splice(index, 1);
        });

        updatedDataAry[i].message = tempMsgAry;
        if (!updatedDataAry[i].isOpen && (updatedDataAry[i].count > 0)) {
            updatedDataAry[i].count -= action.payload.data.length;
        }

        return {
            ...state,
            AppNotificationHandler: updatedDataAry,
        };
    }
    case CLEAR_NOTIFICATION: {
        return {
            AppNotificationHandler: [{
                type: 'nCard',
                isOpen: false,
                count: 0,
                message: [],
                nConfig: {},
                overrideConfig: {},
            }],
        };
    }
    case NOTIFICATION_PULL_HANDLER: {
        return {
            ...state,
        };
    }
    default:
        return state;
    }
};
