/* @flow */
/**
 * @example Sample Usage:
 *  <Dialog
        open= // need to handle in parent component to toggle this dialog
        type='error || success || confirm' // any one value
        customTitle=// need to pass string to override type value
        button=[{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        content='message to show on dialog body'
        size="lg"
        {...other} // see materialUI docs to pass this value *if required
        // https://material-ui.com/api/dialog/
    />
    @prop { size }: Pass size as prop to override the 'md' size, Please don't remove from this file,

    @prop { disableBackdropClick }: Pass disableBackdropClick as prop to override
    the true disableBackdropClick, Please don't remove from this file.

    @prop { disableEscapeKeyDown }: Pass disableEscapeKeyDown as prop to override
    the true disableEscapeKeyDown, Please don't remove from this file,

    @prop { content }: Pass message to show as body on the dialog,
    Please don't hard code any thing in this component.

    @prop { children }: To Override the message to custom ui Pass your component as the child.

    @example exp: <AppDialog>
        can pass any component or multiple component
            <div>
                <input type='text' />
            </div>
        </AppDialog>

    @prop { button }: Pass Array of object to the dialog with button object and callback
    function also,

    button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
    }];

    @prop { type }: Pass your dialog type to show error and confirm or success as a header,

    @prop { customTitle }: to override type header you can pass customTitle as a string
    from you compnent,
 */

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Cancel, CheckCircle } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    size: string,
    classes: Object,
    onClose: boolean,
    disableBackdropClick: boolean,
    disableEscapeKeyDown: boolean,
    content: string,
    button: Array<Object>,
    closeButton: Array<Object>,
    children: typeof Component,
    type: string,
    customTitle: string,
    hideTitle: boolean,
    hideAction: boolean,
    customStyle: Object,
}

const styles = () => ({
    error: {
        width: 40,
        height: 40,
        marginBottom: -12,
        paddingRight: 10,
    },
    success: {
        fill: 'green !Important',
        width: '40px !Important',
        height: '40px !Important',
        marginBottom: -12,
        paddingRight: 10,
    },
    confirm: {
        fill: 'green !Important',
        width: '40px !Important',
        height: '40px !Important',
        marginBottom: -12,
        paddingRight: 10,
    },
});

class AppDialog extends Component<Props, {}> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTitle = (type) => {
        const { classes } = this.props;
        switch (type) {
        case 'error': return (
            <div>
                <Cancel color="error" className={classes.error} /> Error
            </div>
        );
        case 'success': return (
            <div>
                <CheckCircle className={classes.success} /> Success
            </div>
        );
        case 'confirm': return (
            <div>
                <CheckCircle className={classes.confirm} /> Confirm
            </div>
        );
        default: return '';
        }
    }

    render() {
        const {
            size = 'md',
            disableBackdropClick = true,
            disableEscapeKeyDown = true,
            hideTitle = false,
            hideAction = false,
            type,
            button = [], // = [{ title: 'OK', color: 'primary', handleClick: () => {} }],
            closeButton = [],
            content = '',
            children,
            customTitle,
            classes,
            onClose,
            customStyle = {},
            ...other
        } = this.props;

        return (
            <Dialog
                disableBackdropClick={disableBackdropClick}
                disableEscapeKeyDown={disableEscapeKeyDown}
                maxWidth={size}
                aria-labelledby="dialog-title"
                {...other}
                data-qa={`${type}_dialogBox`}
            >
                {!hideTitle &&
                <DialogTitle id="dialog-title" style={{ padding: '20px' }} data-qa="dialog_title">{(!customTitle) ? this.getTitle(type) : customTitle}
                    {closeButton.map(d =>
                        (
                            <IconButton
                                onClick={() => d.handleClick(d)}
                                color={d.color}
                                key={d.title}
                                data-qa={`dialog_${d.title}_btn`}
                                style={{ float: 'right' }}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        ))}
                </DialogTitle>
                }
                <DialogContent data-qa="dialog_content" className={customStyle.content} sx={{ padding: '24px' }}>
                    {!children &&
                        <DialogContentText style={{ minWidth: 400, fontSize: 16 }} component="div" data-qa="dialog_msg">
                            {content}
                        </DialogContentText>
                    }
                    {children}
                </DialogContent>
                {!hideAction &&
                    <DialogActions style={{ padding: '20px', margin: 0 }} data-qa="dialog_actions">
                        {button.map(d =>
                            (
                                <Button
                                    sx={{ margin: '0px 4px !Important' }}
                                    onClick={() => d.handleClick(d)}
                                    color={d.color}
                                    href={d.href || ''}
                                    key={d.title}
                                    variant={d.variant}
                                    data-qa={`dialog_${d.title}_btn`}
                                >
                                    {d.title}
                                </Button>
                            ))}
                    </DialogActions>
                }
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AppDialog);
