/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
    Paper, Typography, Toolbar, IconButton, FormControl, FormGroup, Divider, Grid,
    Button, Checkbox, FormControlLabel, Radio, MenuItem, Select, InputLabel,
} from '@mui/material';
import back from '../../assets/icons/back.png';
import AppLoader from '../AppLoader';
import { getPermissionValue, hasFeature } from '../../helper-classes/utility-functions';
import analytics from '../../analytics/index';
import CustomOverlay from '../SharedComponents/CustomOverlay';
import { USER_ROLE_ARRAY } from '../../containers/UserRole/AddUserRole/constants.userRole';

const styles = () => ({
    root: {
        padding: 16,
        display: 'flex',
    },
    toolbar: {
        backgroundColor: '#343e48',
        color: '#ffffff',
    },
    formControl: {
        padding: '0 16px 16px',
        width: '90%',
        marginLeft: 10,
    },
    roleSection: {
        width: '90%',
        marginTop: '16px',
        fontSize: 21,
    },
    card: {
        backgroundColor: '#999b9e',
    },
    app: {
        paddingTop: 16,
        paddingLeft: 17,
        paddingRight: 23,
        minWidth: 668,
    },
    button: {
        color: '#007aff',
        marginRight: 20,
    },
    backButton: {
        verticalAlign: 'middle',
        paddingRight: 10,
        marginLeft: -12,
    },
    requiredField: {
        fontSize: 14,
        opacity: 0.87,
        whiteSpace: 'nowrap',
    },
    titleWrap: {
        lineHeight: 2,
    },
    divider: {
        borderRadius: 2,
    },
    sideBySide: {
        display: 'flex',
        overflow: 'hidden',
    },
    textField: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 16,
    },
    properties: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    propertyTextField: {
        flex: 'auto',
        margin: '0 16px 0 0',
        maxWidth: 170,
    },
    dropdownContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '12px 0',
        justifyContent: 'space-between',
    },
    dropdown: {
        flex: 'auto',
        maxWidth: 260,
        minWidth: 100,
        margin: '0 20px 0 0',
    },
    checkbox: {
        display: 'flex',
        width: 440,
        whiteSpace: 'nowrap',
        marginLeft: 12,
    },
    rolerow: {
        display: 'flex',
        fontSize: 15,
    },
    container: {
        display: 'flex',
    },
    leftContainer: {
        flex: 'auto',
    },
    rightContainer: {
        flex: 'auto',
        justifyContent: 'right',
    },
    roleContainer: {
        flex: 'auto',
        paddingLeft: '36px !important',
    },
    form: {
        marginTop: 12,
    },
});

export type Props = {
    classes: Object,
    createUserRole: Function,
    updateUserRole: Function,
    deleteUserRole: Function,
    isUpdating: boolean,
    isEdit: boolean,
    userRole: {
        name: string,
        description: string,
        permissionsJSON: any,
        accountUserCount: number,
        accountId: number,
    },
    userRoles: Array<{
        id: number,
        name: string,
    }>,
};

export type State = {
    name: string,
    description: string,
    userCount:number,
    selectedRoleObj: Object | string,
    editModeRoleObj: Object,
    roleMapEnabled: boolean,
    roleMapSelectedVal: string,
    roleDashboardEnabled: boolean,
    roleDashboardSelectedVal: string,
    roleMaintenanceEnabled: boolean,
    roleMaintenanceSelectedVal: string,
    roleReportsEnabled: boolean,
    roleReportsSelectedVal: string,
    roleAlertsEnabled: boolean,
    roleAlertsSelectedVal: string,
    roleEldEnabled: boolean,
    roleEldSelectedVal: string,
    roleAdminEnabled: boolean,
    roleAdminSelectedVal: string,
    roleDriverBehaviorEnabled: boolean,
    roleDriverBehaviorSelectedVal: string,
    roleMessagingEnabled: boolean,
    roleMessagingSelectedVal: string,
    roleDashcamEnabled: boolean,
    roleDashcamSelectedVal: string,
    roleInstallAppEnabled: boolean,
    roleInstallAppSelectedVal: string,
};

class addUserRole extends Component<Props, State> {
    isUserRoleLoaded: boolean;
    isSystemUser: boolean;
    isSaveClicked: boolean;

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            userCount: 0,
            selectedRoleObj: '',
            editModeRoleObj: {},
            roleMapEnabled: false,
            roleMapSelectedVal: 'None',
            roleDashboardEnabled: false,
            roleDashboardSelectedVal: 'None',
            roleMaintenanceEnabled: false,
            roleMaintenanceSelectedVal: 'None',
            roleReportsEnabled: false,
            roleReportsSelectedVal: 'None',
            roleAlertsEnabled: false,
            roleAlertsSelectedVal: 'None',
            roleEldEnabled: false,
            roleEldSelectedVal: 'None',
            roleAdminEnabled: false,
            roleAdminSelectedVal: 'None',
            roleDriverBehaviorEnabled: false,
            roleDriverBehaviorSelectedVal: 'None',
            roleMessagingEnabled: false,
            roleMessagingSelectedVal: 'None',
            roleDashcamEnabled: false,
            roleDashcamSelectedVal: 'None',
            roleInstallAppEnabled: false,
            roleInstallAppSelectedVal: 'None',
        };

        this.isUserRoleLoaded = false;
        this.isSystemUser = false;
        this.isSaveClicked = false;
    }

    UNSAFE_componentWillMount() {
        ValidatorForm.addValidationRule('isEmpty', (value) => {
            if (typeof value === 'string' && value.replace(/\s/gi, '') === '') {
                return false;
            } else if (typeof value === 'number' && value === 0) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('length', value => (!(value.length >= 255)));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.isSaveClicked && nextProps.isEdit && nextProps.userRole
            && typeof nextProps.userRole === 'object' && Object.keys(nextProps.userRole).length > 0) {
            this.updateUserRole(nextProps.userRole, true);
            this.isUserRoleLoaded = true;
            this.isSystemUser = nextProps.userRole.accountId === null;
        }
    }

    updateUserRole = (selectedRoleObj: Object, isEditMode: boolean = false) => {
        if (this.isUserRoleLoaded || !isEditMode) {
            const permissionsJSON = (selectedRoleObj.permissionsJSON) ? JSON.parse(selectedRoleObj.permissionsJSON.replace(/[.]*/gi, '')) : [];
            const newState = { ...this.state };

            if (isEditMode) {
                newState.editModeRoleObj = { ...selectedRoleObj };
                newState.name = selectedRoleObj.name || newState.name;
                newState.description = selectedRoleObj.description || newState.description;
                newState.userCount = selectedRoleObj.accountUserCount || newState.userCount;
            }

            if (permissionsJSON.length > 0) {
                permissionsJSON.map((r) => {
                    const roleIndex = USER_ROLE_ARRAY.findIndex(rObj => (rObj.roleCode === r.code));

                    if (roleIndex > -1) {
                        const roleObj = USER_ROLE_ARRAY[roleIndex];
                        newState[roleObj.roleStateName] = (r.option !== 'None');
                        newState[roleObj.roleValueStateName] = r.option;
                    }
                    return '';
                });
            }
            this.setState({ ...newState });
        }
    }

    handleSelectChange = event => this.setState(
        { [event.target.name]: event.target.value },
        () => this.updateUserRole(event.target.value, false),
    );

    saveUserRole = () => {
        const { isEdit } = this.props;
        const permissionsJSON = [];
        this.isSaveClicked = true;

        USER_ROLE_ARRAY.forEach((r) => {
            permissionsJSON.push({
                code: r.roleCode,
                option: this.state[r.roleValueStateName],
            });
        });
        let sections = '';
        const selectionsArr = [];
        USER_ROLE_ARRAY.forEach((r) => {
            if (this.state[r.roleValueStateName] !== 'None') selectionsArr.push(r.title);
        });
        sections = selectionsArr.join(', ');
        const params = {
            feature: 'Admin',
            name: this.state.name,
            selectedSections: sections.length > 0 ? sections : 'None',
        };
        if (isEdit) {
            const existingRoleData = JSON.parse(this.state.editModeRoleObj.permissionsJSON || '[]');
            existingRoleData.map((r) => {
                const roleIndex = USER_ROLE_ARRAY.findIndex(rObj => (rObj.roleCode === r.code));

                if (roleIndex === -1) {
                    permissionsJSON.push({
                        code: r.roleCode,
                        option: r.option,
                    });
                }
                return true;
            });

            const dataToSend = { ...this.state.editModeRoleObj };
            dataToSend.name = this.state.name;
            dataToSend.description = this.state.description;
            dataToSend.permissionsJSON = JSON.stringify(permissionsJSON);

            this.props.updateUserRole(dataToSend);
            analytics.track('EDIT_USER_ROLE', params);
        } else {
            const requestBody = {
                account: '',
                accountIdProperty: '',
                accountUserCount: '',
                authority: '',
                brandId: '',
                brandLabel: '',
                code: `role_${Math.random().toString(36).substr(2, 5)}`,
                description: `${this.state.description}`,
                displayGroup: 'default',
                displayOrder: '',
                name: `${this.state.name}`,
                noAdmin: false,
                permissionsDigest: '',
                permissionsFlattened: '',
                permissionsJSON: JSON.stringify(permissionsJSON),
                priority: '',
                scope: 'Account',
                systemId: '',
                systemLabel: '',
                version: '',
            };
            this.props.createUserRole(requestBody);
            analytics.track('ADD_USER_ROLE', params);
        }
    };

    deleteUserRole = () => {
        this.props.deleteUserRole(this.state.editModeRoleObj.id);
    }

    getPageTitle = () => {
        if (this.props.isEdit) return (this.isSystemUser) ? 'View' : 'Edit';
        return 'Add';
    }

    handleRoleCheckChange = (e: Object, roleValueStateName: string) => {
        let roleSelectedValue = 'None';

        if (e.target.checked) {
            switch (e.target.value) {
            case 'roleReportsEnabled':
                roleSelectedValue = 'Modify';
                break;
            case 'roleDashcamEnabled':
                roleSelectedValue = 'Modify';
                break;
            default:
                roleSelectedValue = 'View';
                break;
            }
        }

        this.setState({
            [e.target.value]: e.target.checked,
            [roleValueStateName]: roleSelectedValue,
        });
    };

    handleValueChange = (e: Object, valueStateName?: string) => this.setState({
        [valueStateName || e.target.name]: e.target.value,
    });

    getRoleRow = (roleObj: Object) => ((roleObj.disabled !== true) && (!roleObj.featureCheck
        || (roleObj.featureCheck && roleObj.featureCheck.split(', ').some(r => hasFeature(r)))) &&
        <FormGroup row key={`role-row-${roleObj.roleCode}`}>
            <div className={this.props.classes.rolerow}>
                <div className={this.props.classes.checkbox}>
                    <FormControlLabel
                        sx={{ ml: '-14px' }}
                        control={
                            <Checkbox
                                id={roleObj.roleCode}
                                value={roleObj.roleStateName}
                                checked={this.state[roleObj.roleStateName]}
                                onClick={e => this.handleRoleCheckChange(
                                    e,
                                    roleObj.roleValueStateName,
                                )}
                                color="primary"
                            />
                        }
                        label={roleObj.title}
                    />
                </div>
                {roleObj.values.map(rValue => this.getRoleValueRadioButton(roleObj, rValue))}
            </div>
        </FormGroup>
    );

    getRoleValueRadioButton = (roleObj: Object, roleValueObj: Object) => (
        <FormControlLabel
            key={`role-value-${roleObj.roleCode}-${roleValueObj.id}`}
            sx={{ ml: '-14px' }}
            control={
                <Radio
                    id={`${roleObj.roleCode}-${roleValueObj.id}`}
                    name={`rbtn-group-${roleObj.roleCode}`}
                    value={roleValueObj.value}
                    color="primary"
                    checked={this.state[roleObj.roleValueStateName] === roleValueObj.value}
                    disabled={!this.state[roleObj.roleStateName] || roleValueObj.disabled}
                    onChange={e => this.handleValueChange(e, roleObj.roleValueStateName)}
                />
            }
            label={roleValueObj.title}
        />
    );

    render() {
        const showOverlay = this.isSystemUser || false;
        const {
            userRoles, classes, isUpdating, isEdit,
        } = this.props;
        const {
            name = '', description = '', selectedRoleObj = '',
        } = this.state;

        return (
            <div className={classes.app}>
                <Paper>
                    <Toolbar className={classes.toolbar}>
                        <span className={classes.backButton}>
                            <IconButton onClick={() => window.history.back()} size="large">
                                <img src={back} alt="backArrow" />
                            </IconButton>
                        </span>
                        <Typography variant="h6" color="inherit" className={classes.titleWrap}>
                            {!this.props.isUpdating &&
                            <span>{this.getPageTitle()} User Role </span>
                            }
                        </Typography>
                    </Toolbar>
                    <ValidatorForm
                        autoComplete="off"
                        className={classes.form}
                        onSubmit={this.saveUserRole}
                    >
                        <CustomOverlay showOverlay={showOverlay} >
                            <Grid container spacing={3} className={classes.container} >
                                <Grid item xs={4} className={classes.leftContainer}>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <FormGroup>
                                            <TextValidator
                                                id="name"
                                                label="Name*"
                                                className={classes.textField}
                                                name="name"
                                                value={name}
                                                onChange={e => this.handleValueChange(e)}
                                                margin="normal"
                                                validators={['isEmpty', 'length']}
                                                errorMessages={['this field is required', 'Maximum 255 characters is allowed']}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {isEdit &&
                                            <Typography variant="body1" color="inherit" className={classes.titleWrap} sx={{ fontSize: '1rem' }}>
                                                <span>  User Count : {this.state.userCount} </span>
                                            </Typography>
                                            }
                                            <TextValidator
                                                id="description"
                                                label="Comments"
                                                className={classes.textField}
                                                name="description"
                                                margin="normal"
                                                value={description}
                                                onChange={e => this.handleValueChange(e)}
                                                validators={['length']}
                                                errorMessages={['Maximum 255 characters is allowed']}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} />
                                <Grid item xs={4} className={classes.rightContainer}>
                                    <div className={classes.roleSection}>
                                        <InputLabel className={classes.roleSection} shrink htmlFor="selectedRoleObj">
                                        Start From
                                        </InputLabel>
                                        <div className={classes.dropdownContainer}>
                                            <Select
                                                className={classes.dropdown}
                                                onChange={this.handleSelectChange}
                                                value={selectedRoleObj}
                                                name="selectedRoleObj"
                                                displayEmpty
                                            >
                                                <MenuItem value="">Select Role</MenuItem>
                                                {userRoles.length > 0 ? userRoles.map(r =>
                                                    <MenuItem key={`role-${r.id}`} value={r} data-qa={r.name}>{r.name}</MenuItem>) : '' };
                                                {isUpdating &&
                                                    <MenuItem value="">
                                                        <AppLoader
                                                            loaderStyle={styles.loader}
                                                            height={20}
                                                            width={3}
                                                        />
                                                    </MenuItem>
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} className={classes.roleContainer}>
                                    {USER_ROLE_ARRAY.map(role => this.getRoleRow(role))}
                                </Grid>
                            </Grid>
                        </CustomOverlay>
                        <Divider className={classes.divider} />
                        <Toolbar>
                            <Grid item>
                                <div className={classes.requiredField}>
                                * Required Fields
                                </div>
                            </Grid>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                {!this.props.isUpdating &&
                                <Grid item>
                                    <Button
                                        className={!this.isSystemUser ? classes.button : ''}
                                        onClick={() => { window.history.back(); }}
                                        color={this.isSystemUser ? 'primary' : undefined}
                                        variant={this.isSystemUser ? 'contained' : undefined}
                                    >
                                        {showOverlay ? 'Ok' : 'Cancel'}
                                    </Button>
                                    {isEdit && !showOverlay &&
                                    <Button
                                        className={classes.button}
                                        onClick={() => { this.deleteUserRole(); }}
                                        disabled={getPermissionValue('Admin') !== 'Modify'}
                                    >
                                        Delete
                                    </Button>
                                    }
                                    {!showOverlay &&
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={getPermissionValue('Admin') !== 'Modify'}
                                    >
                                        Save
                                    </Button>
                                    }
                                </Grid>
                                }
                            </Grid>
                        </Toolbar>
                    </ValidatorForm>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(addUserRole);
