/* @flow */
import React, { Component } from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import styles from './SearchResults.module.scss';
import NotFound from './NotFound/NotFound';
import Addresses from './Address/Addresses';
import type { typeGlobalSearchColumns, typeAddresses } from '../../containers/Header/types';
import Assets from './Assets/Assets';
import LandMarks from './LandMarks/LandMarks';
import AppLoader from './../AppLoader';
import Drivers from './Drivers/Drivers';
import ItemsShowing from './ItemsShowing';
import analytics from '../../analytics';

type Props = {
    globalSearchData: Array<typeGlobalSearchColumns>,
    searchData: {
        addresses: typeAddresses,
    },
    redirectTo: Function,
    selectedTab: Function,
    updateTab: number,
    onScroll: Function,
    redirectToNearest: Function,
};

type State = {
    activeClass: number,
};

const listCategories = {
    ASSETS: { id: 0, url: '/assets' },
    LANDMARKS: { id: 1, url: '/landmarks' },
    DRIVERS: { id: 2, url: '/drivers' },
    ADDRESSES: { id: 3, url: '' },
    ASSET_GROUPS: { id: 4, url: '' },
    LANDMARK_GROUPS: { id: 5, url: '' },
};

// TODO, May Need to change the few functions based on the API Response
class SearchResults extends Component<Props, State> {
    static defaultProps = {
        query: '',
        updateTab: 0,
    };

    constructor(props: Props) {
        super(props);
        this.state = { activeClass: props.updateTab || 0 };
    }

    getItemCount = (item: typeGlobalSearchColumns, predictions: any) => (predictions[item.category]
        && predictions[item.category].total) || 0;

    generateItems = () => {
        const { searchData } = this.props;
        const items = this.props.globalSearchData.map((item, i) => (
            item.show &&
            <ListItem
                key={item.id}
                data-itemid={item.id}
                data-qa={item.text}
                id={item.text}
                className={(i === this.state.activeClass) ? styles.active : ''}
            >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <i
                        data-itemid={item.id}
                        className={styles[item.icon]}
                    />
                </ListItemIcon>
                <ListItemText
                    data-itemid={item.id}
                    disableTypography
                    primary={
                        <div
                            data-itemid={item.id}
                            id={item.text}
                            className={styles['list-items']}
                        >
                            {item.text}
                        </div>
                    }
                    secondary={null}
                />
                <span
                    data-itemid={item.id}
                    className={`${styles['dropdown-number']}`}
                >
                    { (searchData[item.category] && searchData[item.category].loader) ?
                        <AppLoader
                            type="scale"
                            height={18}
                            width={2}
                            margin="1px"
                        />
                        :
                        this.getItemCount(item, searchData)
                    }
                </span>
            </ListItem>));

        return (
            <List
                id="search-menu-items"
                onKeyDown={() => {}}
                onClick={e => this.getChildData(e.target.dataset)}
            >
                {items}
            </List>);
    }

    getChildData = (e: any) => {
        if (e instanceof Object) {
            const itemId = Number(e.itemid);
            this.setState({ activeClass: itemId - 1 });
            this.props.selectedTab(itemId - 1);
            const params = {
                feature: 'UniversalSearch',
            };
            if (itemId === 1) {
                analytics.track('ASSETS_TAB', params);
            } else if (itemId === 2) {
                analytics.track('LANDMARKS_TAB', params);
            } else if (itemId === 3) {
                analytics.track('DRIVERS_TAB', params);
            } else if (itemId === 4) {
                analytics.track('ADDRESSES_TAB', params);
            }
        }
    }

    calculateListMaxHeight = (predictions: any) => {
        let max = 0;
        if (predictions instanceof Object) {
            Object.keys(predictions).forEach((item) => {
                const total = predictions[item].data && predictions[item].data.length;
                if (total && (max < total)) max = total;
            });
        }
        // In this (57 + 6), 57 is the height of one <li/> item,
        // and remaining 6 is the padding of <li /> item
        return max * (57 + 6);
    };

    getComponentByCategory = (activeClass: number, predictions: any) => {
        const maxheightToSet = this.calculateListMaxHeight(predictions);
        let calculatedHeight = maxheightToSet;
        if (maxheightToSet) {
            if (maxheightToSet <= 213) calculatedHeight = 213;
            else if (maxheightToSet > 510) calculatedHeight = 510;
        }

        switch (activeClass) {
        case listCategories.ASSETS.id: {
            const { assets } = predictions;
            if (assets && assets.data.length) {
                return (
                    <React.Fragment>
                        <Assets
                            predictions={assets.data}
                            setMaxHeight={calculatedHeight}
                            redirectTo={this.props.redirectTo}
                            isLoading={assets.isLoading}
                            onScroll={e => this.props.onScroll(e)}
                        />
                        <ItemsShowing
                            predictions={assets}
                            countClass={styles['predictions-count']}
                        />
                    </React.Fragment>
                );
            }
            return (<NotFound
                setMaxHeight={calculatedHeight}
                listPage={listCategories.ASSETS.url}
            />);
        }
        case listCategories.LANDMARKS.id: {
            const { landmarks } = predictions;
            if (landmarks && landmarks.data.length) {
                return (
                    <React.Fragment>
                        <LandMarks
                            predictions={landmarks.data}
                            setMaxHeight={calculatedHeight}
                            redirectTo={this.props.redirectTo}
                            isLoading={landmarks.isLoading}
                            onScroll={e => this.props.onScroll(e)}
                        />
                        <ItemsShowing
                            predictions={landmarks}
                            rightAlignClass={styles['right-align']}
                            countClass={styles['predictions-count']}
                        />
                    </React.Fragment>);
            }
            return (<NotFound
                setMaxHeight={calculatedHeight}
                listPage={listCategories.LANDMARKS.url}
            />);
        }
        case listCategories.DRIVERS.id: {
            const { drivers } = predictions;
            if (drivers && drivers.data.length) {
                return (
                    <React.Fragment>
                        <Drivers
                            predictions={drivers.data}
                            setMaxHeight={calculatedHeight}
                            isLoading={drivers.isLoading}
                            redirectTo={this.props.redirectTo}
                            onScroll={e => this.props.onScroll(e)}
                        />
                        <ItemsShowing
                            predictions={drivers}
                            rightAlignClass={styles['right-align']}
                            countClass={styles['predictions-count']}
                        />
                    </React.Fragment>);
            }
            return (<NotFound
                setMaxHeight={calculatedHeight}
                listPage={listCategories.DRIVERS.url}
            />);
        }
        case listCategories.ADDRESSES.id: {
            const { addresses } = predictions;
            if (addresses && addresses.data.length) {
                return (
                    <React.Fragment>
                        <Addresses
                            predictions={addresses.data}
                            setMaxHeight={calculatedHeight}
                            redirectTo={this.props.redirectTo}
                            isLoading={addresses.isLoading}
                            redirectToNearest={this.props.redirectToNearest}
                            onScroll={e => this.props.onScroll(e)}
                        />
                        <ItemsShowing
                            predictions={addresses}
                            rightAlignClass={styles['right-align']}
                            countClass={styles['predictions-count']}
                        />
                    </React.Fragment>);
            }
            return (<NotFound
                setMaxHeight={calculatedHeight}
                listPage={listCategories.ADDRESSES.url}
            />);
        }
        case listCategories.ASSET_GROUPS.id: {
            return (<NotFound
                setMaxHeight={calculatedHeight}
                listPage={listCategories.ASSET_GROUPS.url}
            />);
        }
        case listCategories.LANDMARK_GROUPS.id: {
            return (<NotFound
                setMaxHeight={calculatedHeight}
                listPage={listCategories.LANDMARK_GROUPS.url}
            />);
        }
        default: {
            return <NotFound setMaxHeight={calculatedHeight} />;
        }
        }
    };

    render() {
        return (
            <div
                id="search-results"
                data-qa="search-results"
                className={styles['dropdown-holder']}
            >
                <div className={`${styles['dropdown-inner-holder']} ${styles.clearfix}`}>
                    <div className={`${styles['left-dropdown-holder']} ${styles['pull-left']}`}>
                        {this.generateItems()}
                    </div>
                    <div
                        className={`${styles['right-dropdown-holder']} ${styles['pull-left']} ${styles['container-box']}`}
                    >
                        {this.getComponentByCategory(
                            this.state.activeClass,
                            this.props.searchData,
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchResults;
