/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../../hoc';
import AddUserAccess from '../../../components/User/AddUserAccess';
import { getParamValue } from './../../../helper-classes/utility-functions';
import AppLoader from '../../../components/AppLoader';
import Dialog from './../../../components/SharedComponents/Dialog/Dialog';
import { addUserAccess, deleteUserAccess, getUserAccess, updateUserAccess, getAssetBySerialNumber, getLandmarks } from '../../Maintenance/helper-classes/common-services';
import analytics from '../../../analytics';

const themeStyles = () => ({ });

export type Props = {
    history: {
        push: Function,
    },
    match: any,
};

export type State = {
    isDelete: boolean,
    isDeleted: boolean,
    isError: boolean,
    addedSuccessfully: boolean,
    userAccessNotFound: boolean,
    error: Object,
    loading: boolean,
    preloadData: Object,
};

const userObj = {
    accountId: '',
    accountLabel: '',
    config: {
        assetSearchText: null,
        landmarkSearchText: null,
        assetSearchTags: null,
        landmarkSearchTags: null,
        notInAssetGroup: false,
        notInLandmarkGroup: false,
        selectedAssetFilters: [{
            id: 'serialNumber',
            serverType: 'String',
            property: 'serialNumber',
            value: [],
            operator: 'in',
        }],
        selectedLandmarkFilters: [{
            id: 'landmarkId',
            serverType: 'String',
            property: 'landmarkId',
            value: [],
            operator: 'in',
        }],
        selectedAssetGroups: [],
        selectedLandmarkGroups: [],
        selectedReportFilters: [],
        selectedAlertFilters: [],
        selectedMaintenanceFilters: [],
        queryFields: [
            'name',
            'serialNumber',
            'assetGroupName',
            'assetName',
            'assetMake',
            'assetVin',
            'assetYear',
            'assetModel',
            'assetTypeName',
            'assetDescription',
            'address',
            'city',
            'state',
            'zip',
            'landmarkName',
            'stockNumber',
            'assetTags',
        ],
    },
    dateCreated: null,
    description: '',
    lastUpdated: null,
    version: '',
    type: '',
    readOnly: false,
    name: '',
};

class User extends Component<Props, State> {
    isEdit: boolean;
    id: string;
    button: Array<Object>;

    constructor(props) {
        super(props);
        this.state = {
            isDelete: false,
            isDeleted: false,
            isError: false,
            addedSuccessfully: false,
            userAccessNotFound: false,
            error: {},
            loading: false,
            preloadData: {
                assetData: {},
                landmarkData: {},
                assetCriteria: {
                    type: 'groups-tags',
                    groups: [],
                    tags: [],
                    assets: [],
                },
                landmarkCriteria: {
                    type: 'groups-tags',
                    groups: [],
                    landmarks: [],
                },
                name: '',
                description: '',
                readOnly: false,
            },
        };

        this.isEdit = false;
        this.id = getParamValue(this.props, 'id');
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
    }

    componentDidMount() {
        this.loadPageData();
    }

    componentDidUpdate() {
        const { params } = this.props.match;
        if (params.id && params.id !== this.id) this.loadPageData();
    }

    loadPageData = () => {
        this.id = getParamValue(this.props, 'id');
        if (this.id) {
            this.isEdit = true;
            this.updateLoader(true);
            setTimeout(() => {
                getUserAccess(this.id).then((response) => {
                    if (response && response.data) {
                        this.setState({
                            preloadData: this.formatEditData(userObj, response.data),
                        });
                    }
                });
            }, 1000);
        }
    }

    updateLoader = loading => this.setState({ loading });

    getLandmarkIds = landmarks => landmarks.map(landmark => landmark && landmark.landmarkId)

    getAssetIds = assets => assets.map((asset) => {
        if (asset) {
            if (asset.serialNumber) return `${asset.serialNumber}`;
            else if (asset.deviceSerialNumber) return `${asset.deviceSerialNumber}`;
        }
        return null;
    })

    loadSelectedOptions = (configs, type) =>
        new Promise((resolve, reject) => {
            if (type === 'assets' && configs.selectedAssetFilters[0]) {
                const filter = configs.selectedAssetFilters[0].value || [];
                const assetGroups = filter.length === 0 ? configs.selectedAssetGroups : [];

                getAssetBySerialNumber(0, 100, '', assetGroups, filter).then((response) => {
                    if (response && response.data) {
                        this.updateLoader(false);
                        resolve(response);
                    } else {
                        this.updateLoader(false);
                        reject();
                    }
                });
            } else if (type === 'landmarks') {
                const filter = configs.selectedLandmarkFilters || [];
                const landmarkGroups = filter.length === 0 ? configs.selectedLandmarkGroups : [];

                getLandmarks(0, 100, '', landmarkGroups, filter).then((response) => {
                    if (response && response.data) {
                        this.updateLoader(false);
                        resolve(response);
                    } else {
                        this.updateLoader(false);
                        reject();
                    }
                });
            }
        });

    formatSaveData = (template, data) => {
        let userAccessData = Object.assign({}, template);
        userAccessData.name = data.name || '';
        userAccessData.id = (this.state.preloadData && this.state.preloadData.id) || '';
        userAccessData.description = data.description || '';
        userAccessData.config.assetSearchTags = (data.assetData && data.assetData.tags) || null;
        userAccessData.config.selectedAssetGroups =
            (data.assetData && data.assetData.groups && data.assetData.groups) || [];
        userAccessData.config.selectedLandmarkGroups =
            (data.landmarkData && data.landmarkData.groups && data.landmarkData.groups) || [];

        // If there are no assets, then set as empty array
        if (data.assetData && data.assetData.assets.length > 0) {
            userAccessData.config.selectedAssetFilters = [{
                id: 'serialNumber',
                serverType: 'String',
                property: 'serialNumber',
                value: [],
                operator: 'in',
            }];
            userAccessData.config.selectedAssetFilters[0].value =
                this.getAssetIds(data.assetData.assets);
        } else {
            userAccessData.config.selectedAssetFilters = [];
        }

        if (data.landmarkData && data.landmarkData.landmarks) {
            userAccessData.config.selectedLandmarkFilters[0].value =
                this.getLandmarkIds(data.landmarkData.landmarks);
        }

        userAccessData = { ...userAccessData, config: JSON.stringify(userAccessData.config) };
        return userAccessData;
    }

    formatEditData = (template, data) => {
        let userAccessData = Object.assign({}, template);
        if (!data) {
            this.setState({
                isError: true,
                error: { msg: 'Could not open User Access', errors: { name: 'User Access not found' } },
                userAccessNotFound: true,
            });
            return {};
        }

        const configs = JSON.parse(data.config);
        if (configs) {
            this.loadSelectedOptions(configs, 'assets').then((response) => {
                this.updateLoader(true);
                userAccessData.assetCriteria.assets = response.data;
                if (response.data.length > 0) userAccessData.assetCriteria.type = 'custom';
            }).then(() => {
                // Asset is mandatory. Hence moved landmarks after assets
                this.loadSelectedOptions(configs, 'landmarks').then((res) => {
                    userAccessData.landmarkCriteria.landmarks = res.data;
                    if (res.data.length > 0) userAccessData.landmarkCriteria.type = 'custom';
                }).then(() => {
                    userAccessData = { ...userAccessData, config: JSON.stringify(configs) };
                    this.setState({ preloadData: userAccessData });
                    return userAccessData;
                });
            });

            // When there is asset group and no asset is assigned above promise fails
            // Hence adding landmarks section below again
            if (!configs.selectedAssetFilters[0]) {
                this.loadSelectedOptions(configs, 'landmarks').then((res) => {
                    userAccessData.landmarkCriteria.landmarks = res.data;
                    if (res.data.length > 0) userAccessData.landmarkCriteria.type = 'custom';
                }).then(() => {
                    userAccessData = { ...userAccessData, config: JSON.stringify(configs) };
                    this.setState({ preloadData: userAccessData });
                    return userAccessData;
                });
            }
        }

        userAccessData.accountId = data.accountId || '';
        userAccessData.accountLabel = data.accountLabel || '';
        userAccessData.lastUpdated = data.lastUpdated || '';
        userAccessData.globalId = data.globalId || '';
        userAccessData.contextHeaders = data.contextHeaders || '';
        userAccessData.name = data.name || '';
        userAccessData.dateCreated = data.dateCreated || '';
        userAccessData.id = data.id || '';
        userAccessData.description = data.description || '';
        userAccessData.readOnly = data.readOnly || false;
        userAccessData.assetData = (data.assetData && data.assetData.groups[0]) || {};
        userAccessData.config.selectedLandmarkFilters[0].value
            .push((data.landmarkData && data.landmarkData.landmarks[0].landmarkId) || '');

        userAccessData.assetCriteria = {
            assets: [],
            groups: (configs.selectedAssetGroups && configs.selectedAssetGroups.length >= 1 ?
                configs.selectedAssetGroups : []),
            tags: configs.assetSearchTags
                && Object.keys(configs.assetSearchTags).length > 0 ?
                configs.assetSearchTags : [],
            type: userAccessData.type = this.getCriteriaType(configs, 'asset'),
        };

        userAccessData.landmarkCriteria = {
            landmarks: userAccessData.config.selectedLandmarkFilters[0].value,
            groups: (configs.selectedLandmarkGroups &&
                configs.selectedLandmarkGroups.length >= 1 ?
                configs.selectedLandmarkGroups : []),
            type: userAccessData.type = this.getCriteriaType(configs, 'landmark'),
        };

        userAccessData = { ...userAccessData, config: JSON.stringify(configs) };
        this.updateLoader(false);
        return userAccessData;
    }

    getCriteriaType = (configs, criteria) => {
        let type = 'groups-tags';
        if (criteria === 'asset' && configs.selectedAssetGroups && configs.selectedAssetGroups.length > 0) {
            type = 'groups-tags';
        } else if (criteria === 'landmark' && configs.selectedLandmarkGroups && configs.selectedLandmarkGroups.length >= 0) {
            if (configs.selectedLandmarkGroups.length === 0 &&
                configs.selectedLandmarkFilters &&
                configs.selectedLandmarkFilters.length > 0 &&
                configs.selectedLandmarkFilters[0].value &&
                configs.selectedLandmarkFilters[0].value.length > 0) {
                type = 'custom';
            }
        }

        return type;
    }

    formatError = (content, error) => {
        let newContent = content;
        if (error && error.errors) {
            newContent = (
                <div>
                    {newContent}
                    <ul>
                        {
                            Object.keys(error.errors).map(d =>
                                <li key={d}>{d}: {error.errors[d]}</li>)
                        }
                    </ul>
                </div>
            );
        } else if (error && error.message) newContent = `${newContent} ${error.message}`;
        else if (error && error.msg) newContent = `${newContent} ${error.msg}`;
        else newContent = `${newContent} Something went wrong`;

        return newContent;
    }

    getContent = () => {
        let content = '';
        const {
            isError, addedSuccessfully, error, isDeleted, isDelete,
        } = this.state;

        if (this.isEdit) {
            if (isError) {
                content = (isDeleted) ? 'Delete User Access Error :' : 'Edit User Access Error :';
                content = this.formatError(content, error);
            } else if (addedSuccessfully) {
                content = (isDeleted) ? 'User Access deleted successfully' : 'User Access updated successfully';
                if (isDeleted) {
                    analytics.track('DELETE_USER_ACCESS', { feature: 'Admin' });
                }
            }
        } else if (isError) {
            content = 'Add User Access Error :';
            content = this.formatError(content, error);
        } else if (addedSuccessfully) content = 'User Access added successfully';

        if (isDelete) content = 'Are you sure you want to delete the User Access?';
        return content;
    }

    getType = () => {
        const { isDelete, isError, addedSuccessfully } = this.state;
        let type = '';
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleClickOk(),
        }];
        if (addedSuccessfully) type = 'success';
        else if (isError) type = 'error';
        else if (isDelete) {
            type = 'confirm';
            this.button.splice(0, 0, { title: 'Cancel', color: 'primary', handleClick: () => this.setState({ isDelete: false, loading: false }) });
        }
        return type;
    }

    createUserAccess = (data) => {
        let userAccessData = {};
        userAccessData = this.formatSaveData(userObj, data);

        if (data.assetData.type === 'custom' && (!data.assetData || !data.assetData.assets || data.assetData.assets.length === 0)) {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not create User Access', errors: { Asset: 'Please select at least one vehicle' } },
            });
            return;
        } else if (data.assetData.type === 'groups-tags' && data.assetData.groups.length === 0) {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not create User Access', errors: { 'Asset Group(s)': 'Please select at least one asset group' } },
            });
            return;
        } else if (data.assetData.type === 'all-assets') {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not create User Access', errors: { 'Group/Asset': 'Please select at least one group or asset' } },
            });
            return;
        }

        if (data.landmarkData.type === 'custom' && (!data.landmarkData || !data.landmarkData.landmarks || data.landmarkData.landmarks.length === 0)) {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not create User Access', errors: { Landmark: 'Please select at least one landmark' } },
            });
            return;
        } else if (data.landmarkData.type === 'all-landmarks') {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not create User Access', errors: { 'Group/Landmark': 'Please select at least one group or landmark' } },
            });
            return;
        }

        this.updateLoader(true);
        addUserAccess(userAccessData).then((response) => {
            if (response.success) {
                this.updateLoader(false);
                this.setState({ addedSuccessfully: true, isError: false, isDelete: false });
            } else {
                this.updateLoader(false);
                this.setState({
                    addedSuccessfully: false,
                    isError: true,
                    isDelete: false,
                    error: response.errors ? response : { errors: { msg: response.msg || 'Something went wrong' } },
                });
            }
        }, (error) => {
            this.updateLoader(false);
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: error.errors ? error : { errors: { msg: error.msg || 'Something went wrong' } },
            });
        });
    }

    updateUserAccess = (data) => {
        const userAccessData = this.formatSaveData(userObj, data) || {};
        const currentCriteria = Object.assign({}, this.state.preloadData, userAccessData);
        currentCriteria.assetCriteria = data.assetData;
        currentCriteria.landmarkCriteria = data.landmarkData;

        if (data.assetData.type === 'custom' && (!data.assetData || !data.assetData.assets || data.assetData.assets.length === 0)) {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not update User Access', errors: { Asset: 'Please select at least one vehicle' } },
                preloadData: currentCriteria,
            });
            return;
        } else if (data.assetData.type === 'groups-tags' && data.assetData.groups.length === 0) {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not update User Access', errors: { 'Asset Group(s)': 'Please select at least one asset group' } },
                preloadData: currentCriteria,
            });
            return;
        } else if (data.assetData.type === 'all-assets') {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not update User Access', errors: { 'Group/Asset': 'Please select at least one group or asset' } },
                preloadData: currentCriteria,
            });
            return;
        }

        if (data.landmarkData.type === 'custom' && (!data.landmarkData || !data.landmarkData.landmarks || data.landmarkData.landmarks.length === 0)) {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not update User Access', errors: { Landmark: 'Please select at least one landmark' } },
                preloadData: currentCriteria,
            });
            return;
        } else if (data.landmarkData.type === 'all-landmarks') {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: { msg: 'Could not update User Access', errors: { 'Group/Landmark': 'Please select at least one group or landmark' } },
                preloadData: currentCriteria,
            });
            return;
        }

        this.updateLoader(true);
        updateUserAccess(userAccessData).then((response) => {
            this.updateLoader(false);
            if (response.success) {
                this.setState({ addedSuccessfully: true, isError: false, isDelete: false });
            } else {
                this.setState({
                    addedSuccessfully: false,
                    isError: true,
                    isDelete: false,
                    error: response.errors ? response : { errors: { msg: response.msg || 'Something went wrong' } },
                });
            }
        }, (error) => {
            this.setState({
                addedSuccessfully: false,
                isError: true,
                isDelete: false,
                error: error.errors ? error : { errors: { msg: error.msg || 'Something went wrong' } },
            });
            this.updateLoader(false);
        });
    }

    deleteUserAccess = () => this.setState({
        addedSuccessfully: false,
        isError: false,
        isDelete: true,
        error: {},
    }, () => this.updateLoader(true));

    closeModel = () => this.setState({
        isDelete: false,
        isDeleted: false,
        addedSuccessfully: false,
        isError: false,
        error: {},
        loading: false,
    });

    handleClickOk = () => {
        const {
            addedSuccessfully, isDelete, userAccessNotFound, preloadData,
        } = this.state;

        this.closeModel();
        if (addedSuccessfully || userAccessNotFound) this.props.history.push('/user-access');
        if (isDelete) {
            if (!preloadData || !preloadData.id) {
                this.setState({
                    addedSuccessfully: false,
                    isError: true,
                    isDelete: false,
                    isDeleted: false,
                    error: { msg: 'Selected User access not available' },
                    userAccessNotFound: true,
                });
                return;
            }

            this.updateLoader(true);
            deleteUserAccess(preloadData.id, preloadData).then((response) => {
                if (response.success) {
                    this.updateLoader(false);
                    this.setState({
                        addedSuccessfully: true,
                        isError: false,
                        isDelete: false,
                        isDeleted: true,
                    });
                } else {
                    this.updateLoader(false);
                    this.setState({
                        addedSuccessfully: false,
                        isError: true,
                        isDelete: false,
                        isDeleted: true,
                        error: response.errors ? response : { errors: { msg: response.msg || 'Something went wrong' } },
                    });
                }
            }, (error) => {
                this.updateLoader(false);
                this.setState({
                    addedSuccessfully: false,
                    isError: true,
                    isDelete: false,
                    isDeleted: true,
                    error: error.errors ? error : { errors: { msg: error.msg || 'Something went wrong' } },
                });
            });
            this.setState({ isDelete: false, isDeleted: false });
        }
    }

    render() {
        const {
            isDelete, addedSuccessfully, preloadData, isError, loading,
        } = this.state;

        return (
            <React.Fragment>
                { loading && <AppLoader type="fullScreen" /> }
                <AddUserAccess
                    createUserAccess={data => this.createUserAccess(data)}
                    updateUserAccess={data => this.updateUserAccess(data)}
                    deleteUserAccess={() => this.deleteUserAccess()}
                    isUpdating={loading}
                    isEdit={this.isEdit}
                    preloadData={preloadData}
                    history={this.props.history}
                />
                <Dialog
                    open={addedSuccessfully || isError || isDelete}
                    type={this.getType()}
                    customTitle=""
                    button={this.button}
                    content={this.getContent()}
                    size="lg"
                />
            </React.Fragment>
        );
    }
}

export default withStyles(themeStyles)(reduxConnect(User));
