/* @flow */

import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.recipent';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import { ENTER_OR_EMAIL_MOBILE, INPUT_PLACE_HOLDER, DELETE_ALERT_CONFIRM_MSG, DELETE_ALERT_SUCCESS_MSG, RECIPIENT_LIST_PREF_KEY } from './constants.recipentList';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    showHideColumns,
    rearrangeColumns,
    isUpdatePreference,
} from '../../helper-classes/listUtil';
import AppLoader from '../../components/AppLoader';
import analytics from '../../analytics';

export type Props = {
    isLoading: boolean,
    fetchRecipientList: Function,
    fetchRecipientAlerts: Function,
    returnEmptyRecipients: Function,
    deleteAlert: Function,
    resetTable: Function,
    history: {
        push: Function,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
        isGroup: boolean,
    },
    recipient: string,
    recipients: Array<{}>,
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    dialogActionType: string,
    openDialog: boolean,
    dialogMessage: string,
    selectedRecord: {
        alertSpecId: string,
        reportSpecId: string,
        maintenanceSpecId: string,
        type: string,
        reportAlertType: string,
        reportAlertName: string,
        createdBy: string
    },
    showListing: boolean,
};
const themeStyles = () => ({
    checked: {
        color: '#007aff',
    },
    label: {
        color: '#696969',
    },
    button: {
        margin: '0 10px',
    },
});

const dialogSuccess = 'success';
const dialogError = 'error';
const dialogDelete = 'delete';

class RecipientList extends Component<Props, State> {
    search: string;
    isFirstTime: boolean;
    button: Array<Object>;
    userPreferences: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            showListing: false,
            tableData: {
                isDeletable: true,
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
                ischeckBox: false,
                isGroup: false,
            },
            recipient: '',
            recipients: [],
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'ASC',
            orderBy: 'type',
            dialogActionType: '',
            openDialog: false,
            dialogMessage: '',
            selectedRecord: {
                alertSpecId: '',
                reportSpecId: '',
                maintenanceSpecId: '',
                type: '',
                reportAlertType: '',
                reportAlertName: '',
                createdBy: '',
            },
        };
        this.search = '';
        this.isFirstTime = true;
        this.button = [];
    }

    UNSAFE_componentWillMount() {
        getUserPreference(this, RECIPIENT_LIST_PREF_KEY);
    }

    UNSAFE_componentWillReceiveProps(nextprops: any) {
        if (nextprops.recipients && nextprops.recipients.length > 0) {
            const { recipients } = nextprops;
            this.setState({ recipients });
        } else {
            this.setState({ recipients: [] });
        }

        if (nextprops.alerts.length > 0) {
            const { alerts, totalAlerts } = nextprops;
            const { tableData } = this.state;
            tableData.listData = alerts;
            tableData.totalRecords = totalAlerts;
            this.setState({ tableData });
        } else {
            const { tableData } = this.state;
            tableData.listData = [];
            tableData.totalRecords = 0;
            this.setState({ tableData });
        }


        if (nextprops.deleteResponse) {
            if (nextprops.deleteResponse.success) {
                this.setState({
                    dialogActionType: dialogSuccess,
                    openDialog: true,
                    dialogMessage: DELETE_ALERT_SUCCESS_MSG,
                });
            } else if (!nextprops.deleteResponse.success && nextprops.deleteResponse.message) {
                this.setState({
                    dialogActionType: dialogError,
                    openDialog: true,
                    dialogMessage: nextprops.deleteResponse.message,
                });
            }
        }
    }

    getData = () => {
        const { tableData } = this.state;
        tableData.listData = [];
        tableData.totalRecords = 0;
        this.setState({ recipients: [], tableData, showListing: true });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { type: 'Report/Alert', ...getColumnPreferences('type', preferences) },
            { reportAlertType: 'Report/Alert Type', ...getColumnPreferences('reportAlertType', preferences) },
            { reportAlertName: 'Report/Alert Name', ...getColumnPreferences('reportAlertName', preferences) },
            { createdBy: 'Created By', ...getColumnPreferences('createdBy', preferences) },
            { savedScheduled: 'Saved/Scheduled', ...getColumnPreferences('savedScheduled', preferences) },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage: value, order, orderBy });
        this.setState({
            pageNumber: rows,
            rowsPerPage: value,
            order,
            orderBy,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, RECIPIENT_LIST_PREF_KEY);
            }
        });
        this.props.fetchRecipientAlerts(
            this.state.recipient,
            rows,
            value,
            order,
            orderBy,
        );
    };

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchRecipientAlerts(
            this.state.recipient,
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
        );
        this.setState({
            order,
            orderBy,
        }, () => {
            saveUserPreference(this, RECIPIENT_LIST_PREF_KEY);
        });
        const params = {
            feature: 'Admin',
            sortColumn: orderBy,
        };
        analytics.track('RECIPIENTS_SORT', params);
    };
    SuggestionSearch = (value) => {
        this.handleClearSearch();
        if (!value) {
            this.resetResults();
            return;
        }
        if (value.length >= 3) {
            this.isFirstTime = false;
            this.props.fetchRecipientList(value);
        } else {
            this.resetResults();
        }
    };
    resetResults = () => {
        this.setState({
            recipients: [],
        });
        this.props.returnEmptyRecipients();
        this.isFirstTime = true;
    };

    handleClearSearch = () => {
        this.props.resetTable();
    };

    handleSuggestionClick = (recipient) => {
        this.props.fetchRecipientAlerts(
            recipient,
            this.state.pageNumber,
            this.state.rowsPerPage,
            this.state.order,
            this.state.orderBy,
        );
        this.setState({ recipient });
    };

    handleDialogConfirm = () => {
        if (this.state.dialogActionType === dialogDelete) {
            this.props.deleteAlert(this.state.selectedRecord, this.state.recipient);
        } else if (this.state.dialogActionType === dialogSuccess) {
            this.props.fetchRecipientAlerts(
                this.state.recipient,
                this.state.pageNumber,
                this.state.rowsPerPage,
                this.state.order,
                this.state.orderBy,
            );
        }

        this.setState({
            dialogActionType: '',
            openDialog: false,
        });
    };

    clickRow = (n: any, event: String) => {
        if (event !== undefined && event === 'delete') { // NOSONAR
            this.state.selectedRecord.alertSpecId = n.alertSpecId;
            this.state.selectedRecord.reportSpecId = n.reportSpecId;
            this.state.selectedRecord.maintenanceSpecId = n.maintenanceSpecId;
            this.state.selectedRecord.type = n.type;
            this.state.selectedRecord.reportAlertType = n.reportAlertType;
            this.state.selectedRecord.reportAlertName = n.reportAlertName;
            this.state.selectedRecord.createdBy = n.createdBy;
            this.setState({
                dialogActionType: dialogDelete,
                openDialog: true,
            });
        }
    };


    getDialogContent = () => {
        const { dialogActionType } = this.state;
        let content = DELETE_ALERT_CONFIRM_MSG;
        if (dialogActionType === dialogError) {
            content = this.state.dialogMessage ? this.state.dialogMessage : 'Delete failed';
        } else if (dialogActionType === dialogSuccess) {
            content = this.state.dialogMessage
                ? this.state.dialogMessage
                : DELETE_ALERT_SUCCESS_MSG;
        }
        return content;
    };


    getDialogType = () => {
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.handleDialogConfirm(),
        }];
        const { dialogActionType } = this.state;
        let type = 'confirm';
        if (dialogActionType === dialogDelete) {
            type = 'confirm';
            this.button.splice(0, 0, {
                title: 'Cancel',
                color: 'primary',
                handleClick: () => this.setState({
                    dialogActionType: '',
                    openDialog: false,
                }),
            });
        } else if (dialogActionType === dialogSuccess) {
            type = 'success';
        } else if (dialogActionType === dialogError) {
            type = 'error';
        }
        return type;
    };

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: RECIPIENT_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: RECIPIENT_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        const { openDialog } = this.state;
        return (
            <MiniDrawer
                redirectTo={this.props.history.push}
            >
                {this.state.showListing ?
                    <ListComponent
                        redirectTo={this.props.history.push}
                        tableData={this.state.tableData}
                        loadNextPage={this.loadNextPage}
                        rearrangeColumns={this.rearrangeColumns}
                        resetTable={this.props.resetTable}
                        isUpdating={this.props.isLoading}
                        showHideColumn={this.showHideColumn}
                        sortTableColumn={this.sortTableColumn}
                        tableTitle="Recipients"
                        tableButtons={[]}
                        autoSuggestive={{
                            isSuggestion: true,
                            suggestions: this.state.recipients,
                            onSuggestionClick: this.handleSuggestionClick,
                            onSuggestionSearch: this.SuggestionSearch,
                            placeholder: INPUT_PLACE_HOLDER,
                            onClearSearch: this.handleClearSearch,
                        }}
                        ischeckBox={false}
                        clickRow={this.clickRow}
                        resizableHeader
                        language={{
                            emptyTable: this.isFirstTime ? ENTER_OR_EMAIL_MOBILE : undefined,
                        }}
                        orderBy={this.state.orderBy}
                        order={this.state.order}
                        rowsPerPage={this.state.rowsPerPage}
                    />
                    :
                    <div style={{ height: '100%' }}>
                        <AppLoader type="fullScreen" />
                    </div>
                }
                <Dialog
                    open={openDialog}
                    type={this.getDialogType()}
                    customTitle=""
                    button={this.button}
                    content={this.getDialogContent()}
                    size="lg"
                />
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => ({
    recipients: state.recipients.recipients,
    alerts: state.recipients.alerts,
    totalAlerts: state.recipients.totalAlerts,
    deleteResponse: state.recipients.response,
    isLoading: state.recipients.isLoading,
});

export default withStyles(themeStyles)(reduxConnect(RecipientList, actions, mapStateToProps));
